<template>
    <div class="pageHeight">
        <v-card class="mt-1">
            <v-layout>
                <v-navigation-drawer permanent left>
                    <v-list density="compact">
                        <v-list-item
                            v-for="(item, i) in sidePanelItems"
                            :key="i"
                            :value="item"
                            color="primary"
                        >
                            <template v-slot:prepend>
                            <v-icon :icon="item.icon"></v-icon>
                            </template>

                            <v-list-item-title>{{item.text}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-navigation-drawer>
                <v-main>
                    <div align-self-start class="pa-4">
                        <div>
                            <v-card id="about" flat>

                                <v-card-title class="pa-4">
                                    <h2>What is Ellipse?</h2>
                                </v-card-title>
                                <v-card-text>
                                    <v-row class="pb-2">
                                        <v-col cols="12">
                                            <v-card>
                                                <v-card-text>
                                                    <h1 style="font-weight: 500;" class="text-primary font-weight-500">Your AEC Data Studio</h1>
                                                    <br>
                                                    <div class="statement pl-2 mb-6">
                                                        Ellipse is a cloud-based application giving multiple stakeholders a central location to view and interact with a filtered space in a larger project.
                                                        Through industry-tailored dashboards combining interactive 2d drawings, 3d models, and BIM / Analytical data, each stakeholder is presented with just the information they need in the format they want.
                                                        Ellipse encourages collaboration and communication across multiple teams, and locations, enabling feedback and contribution through comments, markups, and sharing, from the overall concept to the smallest detail.
                                                        This allows architects, engineers, contractors, and other stakeholders to collaborate in real time, access and view project data, and make informed decisions.
                                                    </div>
                                                    <v-row class="ma-3" v-for="option in features" :key="option.title">
                                                        <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="1">
                                                            <v-card width="120px" height="120px" min-width="120px" min-height="100px"
                                                             elevation="1" style="position:relative">
                                                                <v-icon color="primary" size="75"
                                                                    style="top: 45%; left: 45%; transform: translate(-50%, -50%);">
                                                                    {{ option.icon }}
                                                                </v-icon>
                                                            </v-card>
                                                        </v-col>
                                                        <v-col cols="12" xs="7" sm="7" md="6" lg="6" xl="6">
                                                            <h2 class="ml-2 pt-2">
                                                                {{ option.title }}
                                                            </h2>
                                                            <p class="ml-2 pt-1">
                                                                {{ option.description }}
                                                            </p>
                                                        </v-col>
                                                        <v-col cols="12" xs="2" sm="2" md="4" lg="4" xl="6">
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>


                                </v-card-text>
                            </v-card>
                        </div>
                        <v-divider></v-divider>
                        <div>
                            <v-card id="pricing" flat>

                                <v-card-title class="header">
                                    <h2>Ellipse Pricing</h2>
                                </v-card-title>
                                <v-card-text>
                                    <v-card class="mb-4">
                                        <v-card-text>
                                            <span style="color:blueviolet" class="statement">Ellipse is currently in Alpha testing and is free to use at this time. </span>
                                            <br>
                                            <span >Ellipse will be a paid subscription application in the future. More cost information will be available when Ellipse enters beta. Please learn about the subscription options below. </span>
                                        </v-card-text>
                                    </v-card>
                                    <v-row>
                                        <v-col cols="12" sm="0">
                                            </v-col>
                                            <v-col cols="12" sm="12">
                                    <v-row class="pt-4">
                                        <v-col v-for="option in pricingOptions" :key="option.title" cols="12" sm="3"
                                            class="px-2">

                                            <v-card class="rounded-xl " elevation="4" height="500" variant="outlined">

                                                <v-card-title class="pl-8 pt-6 pb-1">
                                                    <h2>{{ option.title }}</h2>
                                                </v-card-title>
                                                <v-card-text class="pt-0">
                                                    <v-list-item three-line>
                                                        <v-list-item-title>
                                                            <span class="text-h4 mb-1" style="font-weight:bold;">${{
                                                                option.price }}</span>
                                                            <span>/mo</span>
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle class="py-2" >{{ option.description
                                                        }}</v-list-item-subtitle>
                                                        
                                                        <v-table density="compact">
                                                            <tbody>
                                                                <tr v-for="item in option.features" :key="item">
                                                                    <td><v-icon small>fa-solid fa-minus</v-icon></td>
                                                                    <td>{{ item }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </v-table>
                                                    </v-list-item>
                                                </v-card-text>

                                                <v-btn disabled color="secondary" variant="outlined" rounded class="pr-3" style="position:absolute; bottom:10px; right:10px; left:10px">
                                                    {{ option.button }}
                                                </v-btn>
                                            </v-card>

                                        </v-col>
                                    </v-row>
                                            
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                        <v-divider></v-divider>
                        <div>
                            <v-card id="learn" flat>

                                <v-card-title class="header">
                                    <h2>Get Started</h2>
                                </v-card-title>
                                <v-card-text color="secondary">
                                    <v-card>
                                <v-card-text>
                                    <span style="color:darkSlate" class="statement">Check out these resources to help you get started with Ellipse. </span>
                                    <br>

                                    <v-card v-for="option in resources" :key="option.title" class="mt-4 pa-0"  :href="option.link" target="_blank">
                                        <v-card-text class="ma-0 pa-2 d-flex justify-start ">
                                                    <v-card width="75px" height="75px" min-width="75px" min-height="75px"
                                                        align="center" class="pt-3 mr-3" flat>
                                                        <v-icon color="primary" size="50">{{ option.icon }}</v-icon>
                                                    </v-card>
                                                    <div class="pt-3">
                                                    <h2 >
                                                        {{ option.title }}
                                                    </h2>
                                                    <p>
                                                        {{ option.description }}
                                                    </p>
                                                </div>
                                        </v-card-text>
                                    </v-card>
                                    
                                </v-card-text>
                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </div>
                        <v-divider></v-divider>
                        <div>
                            <v-card id="contact" flat >

                                <v-card-title class="header">
                                    <h2>Contact Us</h2>
                                </v-card-title>
                                <v-card-text color="secondary">
                                    <v-card>
                                <v-card-text>
                                    <span style="color:rgb(var(--v-theme-darkSlate))" class="statement">Want to learn more about Ellipse? </span>
                                    <br>
                                    <br>
                                    <div class="d-flex justify-center align-center flex-column flex-md-row fill-height">


                                    <v-btn color="primary" variant="outlined" large class="mx-4" href="mailto:core@thorntontomasetti.com"><v-icon size="small" class="mr-2">{{ icons.contact }}</v-icon>Email</v-btn>
                                    <v-btn color="primary" variant="outlined" large class="mx-4" href="https://twitter.com/tt_core"><v-icon size="small" class="mr-2">{{ icons.twitter }}</v-icon>Twitter</v-btn>
                                    <v-btn color="primary" variant="outlined" large class="mx-4" href="https://www.instagram.com/tt_corestudio/"><v-icon size="small" class="mr-2">{{ icons.instagram }}</v-icon>Instagram</v-btn>
                                    <v-btn color="primary" variant="outlined" large class="mx-4" href="https://www.linkedin.com/company/core-studio-tt"><v-icon size="small" class="mr-2">{{ icons.linkedIn }}</v-icon>LinkedIn</v-btn>
                                    </div>
                                    <div class="d-flex justify-center align-center flex-column flex-md-row fill-height pt-2">
                                    <small>Email us at: <a style="color: rgb(var(--v-theme-primary))" href="mailto:core@thorntontomasetti.com"> core@thorntontomasetti.com </a></small>
                                    </div>

                                </v-card-text>
                                    </v-card>

                                </v-card-text>
                            </v-card>
                        </div>
                    </div>
                </v-main>
            </v-layout>
        </v-card>

    </div>
</template>
<script>
import * as easings from 'vuetify/lib/services/goto/easing-patterns'
import * as icons from "@/utilities/EllipseIcons.js";

export default {
    setup() {
    return {icons}
    },
    data() {
        return {
            options () {
                return {
                    offset: -60,
                    duration: 300,
                }
            },
            features: [
                {
                    title: "Cloud-Based Platform",
                    description: "Securely host all project models, drawings, data, and assets on industry-standard cloud storage. This data can be accessed from anywhere, at any time, using an internet connection through the web app and software clients.",
                    icon: "fa-solid fa-cloud fa-fw",
                },
                {
                    title: "AEC Software Integration",
                    description: "Extract AEC content out of its platform silos with BIM, CAD, Analysis, and spreadsheet software integration. Upload-tagged 3D models, 2D drawings, data, and images, to unlock new insights in a cross-coordinated platform. Download filtered snapshots of models and drawings.",
                    icon: "fa-solid fa-computer fa-fw",
                },
                {
                    title: "Visualization",
                    description: "Explore models, drawings, and data with embedded lightweight viewers. The model and drawing viewers enable users to view, navigate, crop, select, and interact with its contents. Interactions are cross-linked between viewers and data visualizations coordinating elements.",
                    icon: "fa-solid fa-fill-drip fa-fw",
                },
                {
                    title: "Filtering",
                    description: "Search and filter project data based on parameters and categories, such as area, building type, or material type. Cross filters to find options that meet project criteria. Visualize results by coloring, highlighting, or hiding elements based on their values, layers, or status.",
                    icon: "fa-solid fa-filter fa-fw",
                },
                {
                    title: "Collaboration",
                    description: "Encourage cross-team communication with a range of collaboration tools including, marking up drawings and models, attaching files to content, commenting across the platform, and sharing anything from a comment to a page.",
                    icon: "fa-solid fa-handshake fa-fw",
                },
                {
                    title: "Flexible Layouts",
                    description: "Customize the layout of a workspace and page with a flexible dashboard editor. Build project pages to suit user-specific needs or curate content access for a broader audience. Create custom dashboards to visualize project data, present design narratives, analyze models and drawings, or anything in between. Choose from a wide array of dashboard widgets to define an array of experiences.",
                    icon: "mdi:mdi-monitor-dashboard",
                },
            ],
            pricingOptions: [
                {
                    title: "Free",
                    price: "0",
                    description: "Get 1 Notebook with access to all online Ellipse features.",
                    features: ["1 Notebook", "Limited Exporters", "Comment on other Notebooks", "Limited # Models", "Limited # Drawings", "Limited # Datasets", "No dynamic data sources"],
                    button: "Sign Up Now",
                    color: "secondary",
                },
                {
                    title: "Single User",
                    price: "-",//50
                    description: "Sign up for full access to everything Ellipse has to offer.",
                    features: ["Unlimited Notebooks", "Collaborate with other users", "API data access", "All Exporters"],
                    button: "Coming Soon",
                    color: "secondary",
                },
                {
                    title: "Organization",
                    price: "-",//500
                    description: "Get full access to Ellipse for your team and organization management",
                    features: ["Admin Dashboards", "Access Control", "User Grouping & Permissions", "User Policy Management", "Central Billing"],
                    button: "Coming Soon",
                    color: "secondary",
                },
                {
                    title: "Enterprise",
                    price: "-",
                    description: "Custom solutions developed to meet your organization's requirements",
                    features: [""],
                    button: "Contact Us",
                    color: "secondary",
                },
            ],
            resources: [
                {
                    title: "User Guide",
                    description: "Learn how to get started using Ellipse with this in depth guide. ",
                    icon: icons.userGuide ,
                    link: "https://core-studio.gitbook.io/ellipse/"
                },
            ],
            sidePanelItems: [
                {text: 'About', icon: icons.about , target: "#about"},
                {text: 'Pricing', icon: icons.price , target: "#pricing"},
                {text: 'Learn', icon: icons.userGuide, taret: "#learn"},
                {text: 'Contact', icon: icons.contact , target: "#contact"},
            ]
        };
    },
    methods: {
        
    },
    created() { },
};
</script>

<style scoped>
.statement {
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
}</style>
