<template>
  <div class="widgetPadding" style="height:100%">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="(getAttrData.length === 0 && getSelectedPageDatasets.length != 0) || datasetSwitchingLoading">
    </Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content" :item="grid_item"></WidgetTitle>

    <!-- Scrub Action -->
    <v-container class="px-0 py-0 d-flex justify-space-between">
      <h5 class="WidgetSubHeader mr-2 d-flex align-center justify-self-end">Scrub Action: 
        <span v-if="grid_item.instance_setting.data.scrubAction != 'User Preference'" class="ml-1">{{ grid_item.instance_setting.data.scrubAction}}</span>
        <div v-else style="width: 100px">
          <v-select
            :items="scrubUserPreferences"
            v-model="grid_item.instance_setting.data.userPreferenceScrubAction"
            style="margin-top: -10px" class="ml-1"
          > </v-select>
        </div>
      </h5>
    </v-container>

    <!-- Chart -->
    <div ref="chartCanvas" :id="canvasId()" style="height: 100%; width:100%"></div>

  </div>
</template>
  
<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../ui/WidgetTitle.vue";
import chartMgrInstance from "@ttcorestudio/viewer_data/library_files/PlotMgr.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import {useAssetsStore} from "@/store/AssetsStore.js";

//globals
//these variables are shared accross all component instances
let chartManager = {};
let chartInstances = {};
let chartCount = 0;

export default {
    components: { Spinner, NoDataWarning, WidgetIcon,WidgetTitle },
    props: ["grid_item"],
    setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getHighlightedElements,
      getSelectedElements,
      getGlobalProperty,
    } = storeToRefs(notebookPropsStore)
    const {
      getColorByData,
      getFilterByData,
      getAttrData,
      attrHeaders,
      getAttrHeadersNumerical,
      getAttrHeaderNames,
      getUniqueAttrTotalByHeader
    } = storeToRefs(dataGraphicsStore)
    const {
      addFilter,
      updateFilterByProperties
    } = dataGraphicsStore
    const {
      getSelectedPageDatasets,
      getSelectedPageDataset
    } = storeToRefs(assetsStore)
    const {
      setHighlightedElements,
      removeFromSelectedElements,
      addToSelectedElements,
      addRangeToSelectedElements
    } = notebookPropsStore
    return {notebookPropsStore, dataGraphicsStore, assetsStore,
    getHighlightedElements, getGlobalProperty, getSelectedElements, getSelectedPageDataset,
    getSelectedPageDatasets, getEditMode, getColorByData, getFilterByData, getAttrData,attrHeaders, getAttrHeadersNumerical, getAttrHeaderNames,
    setHighlightedElements, removeFromSelectedElements, addToSelectedElements, addRangeToSelectedElements, getUniqueAttrTotalByHeader,
    addFilter, updateFilterByProperties
    }
  },
    data() {
      return {
        updatingWidgets: false,
        datasetSwitchingLoading: false,
        selectedAttrs: [],
        selectedAttrTypes: [],
        minRadius:6,
        maxRadius:12,
        scrubAction: "Select",
        scrubUserPreferences: ["Filter", "Select"],
        lastSelectedIds: [],
        respondToFiltering: false
      };
    },
    computed: {
      widgetIcon() {
        let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
        return result;
      },
    },
    created() {
      this.verifyInstanceSettings();
    },
    async mounted() {
      this.verifyInstanceSettings();
      await this.creatingInstanceAndCanvas();

    setTimeout(async () => {
      await this.updateChartValues();
    }, 500);
    
    //Listen for changes in the notebookPropsStore
    this.notebookPropsStore.$onAction(({name, args}) => {
      if (name === 'setHighlightedElements') {
        this.onNewElementsHighlighted()
      }
      if (name === 'setGlobalHighlightColor'){
        this.globalHighlightColorEvent(args[0])
      }
      if (name === 'setGlobalSelectionColor'){
        this.globalSelectionColorEvent(args[0])
      }
      if (name === 'setGlobalSignificantDigits'){
        this.globalSignificantDigitsEvent(args[0])
      }
    })
    //Listen for changes in the dataGraphicsStore
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateColorByProperties') {
          this.onNewColorByUpdated()
        }
        if (name === 'updateFilterByProperties') {
          this.onNewFilterByUpdated()
        }
      })
    })
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.newDatasetLoadStartedEvent()
        }
      })
    })


    },
    beforeUnmount() {
      if (chartInstances[this.grid_item.i]) {
        chartMgrInstance.clearPlotByName(this.grid_item.i);
        chartInstances[this.grid_item.i].destroy();
        delete chartInstances[this.grid_item.i];
      }
    },
    watch: {
      getSelectedElements: {
        deep: true,
        handler() {
          if (chartInstances[this.grid_item.i]) {
            chartMgrInstance.selectElementsInAllPlots(this.getSelectedElements);
          }
        }
      },
      "grid_item.instance_setting.data.selectedAttrs": function () {
        this.selectedAttrChanged();
      },
      "grid_item.instance_setting.data.respondToFiltering": function () {
        this.updateChartValues();
      },
    },
    methods: {
      canvasId() {
        return "chart" + this.grid_item.i;
      },
      verifyInstanceSettings() {
        let currentData = this.grid_item.instance_setting.data
        let defaultData = {
          scrubAction: this.scrubAction,
          userPreferenceScrubAction: this.scrubAction
        }
        // Right hand object overwrites left hand object props. 
        this.grid_item.instance_setting.data={...defaultData,  ...currentData}
        
        if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};

        if(this.getUniqueAttrTotalByHeader.length>0) if(this.getUniqueAttrTotalByHeader[0].total<50) this.selectedAttrs.push(this.getUniqueAttrTotalByHeader[0].name);
        if(this.getUniqueAttrTotalByHeader.length>1) if(this.getUniqueAttrTotalByHeader[1].total<50) this.selectedAttrs.push(this.getUniqueAttrTotalByHeader[1].name);

        if (!this.grid_item.instance_setting.data.selectedAttrs) this.grid_item.instance_setting.data.selectedAttrs = this.selectedAttrs;

        if (!this.grid_item.instance_setting.data.displaySettings) this.grid_item.instance_setting.data.displaySettings = {};
        if (!this.grid_item.instance_setting.data.displaySettings.minRadius) this.grid_item.instance_setting.data.displaySettings.minRadius = this.minRadius;
        if (!this.grid_item.instance_setting.data.displaySettings.maxRadius) this.grid_item.instance_setting.data.displaySettings.maxRadius = this.maxRadius;
        if (!this.grid_item.instance_setting.data.displaySettings.respondToFiltering) this.grid_item.instance_setting.data.displaySettings.respondToFiltering = this.respondToFiltering;

        if (!this.grid_item.instance_setting.data.uiSettings) this.grid_item.instance_setting.data.uiSettings = {};

      },
      newDatasetLoadStartedEvent() {
        this.datasetSwitchingLoading = true;
      },
      selectedAttrChanged() {
        if (this.grid_item.instance_setting.data.selectedAttrs) {
            for (let i = 0; i < this.grid_item.instance_setting.data.selectedAttrs.length; i++) {
          if(!this.getAttrHeaderNames.includes(this.grid_item.instance_setting.data.selectedAttrs[i])) this.grid_item.instance_setting.data.selectedAttrs[i] = this.getAttrHeaderNames[0];
          }
        }
        this.updateChartValues();
      },
      async updateChartValues(){
        if (!chartInstances[this.grid_item.i]) return;

        this.selectedAttrTypes = [];
        this.grid_item.instance_setting.data.selectedAttrs.forEach(attr => {
          var type = this.attrHeaders.find(x => { return x.name === attr });
          if(type) {
            this.selectedAttrTypes.push(type['dataType'].toLowerCase());
          }
          else{
            this.selectedAttrTypes.push('string');
          }
        });
        
        let scrubAction = this.grid_item.instance_setting.data.scrubAction
        let data = (this.getFilterByData.graphicData && this.grid_item.instance_setting.data.respondToFiltering && scrubAction != 'Filter') ? this.getFilterByData.graphicData : this.getAttrData
        chartInstances[this.grid_item.i].addParallelCoordinatePlot(
          data,
          "ellipseId",
          this.grid_item.instance_setting.data.selectedAttrs,
          this.selectedAttrTypes,
          false,
        );
        await this.onNewColorByUpdated();
        await this.onNewElementsSelected();
        await this.onNewElementsHighlighted();
      },
      globalHighlightColorEvent(highlight_color) {
        if (!chartInstances[this.grid_item.i]) return;
          chartInstances[this.grid_item.i].highlightColor = highlight_color;
      },
      globalSelectionColorEvent(selection_color) {
        if (!chartInstances[this.grid_item.i]) return;
          chartInstances[this.grid_item.i].selectionColor = selection_color;
      },
      globalSignificantDigitsEvent(significant_digits) {
        if (!chartInstances[this.grid_item.i])  return;
          chartInstances[this.grid_item.i].digits = significant_digits;
      },
      async creatingInstanceAndCanvas() {
        //Get Div for new Viewer
        chartCount += 1;
        let canvasId = this.grid_item.i;
        let canvas = this.$refs.chartCanvas;

        let selectionColor = this.getGlobalProperty.global_setting.selection_color;

        let highlightColor = this.getGlobalProperty.global_setting.highlight_color;
        let backgroundColor = this.grid_item.local_setting.foreground_color;

        let defaultColor = this.$vuetify.theme.current.colors.darkSlate;

        let customChartSettings = {
          defaultColor: defaultColor,
          backgroundColor: backgroundColor,
          selectionColor: selectionColor,
          highlightColor: highlightColor,
        };

        let response = chartMgrInstance.addPlotInstance("parallelCoordinate", canvas, canvasId, customChartSettings);

        if (response["status"] == "success") {
          chartInstances[this.grid_item.i] = chartMgrInstance.getPlotByName("parallelCoordinate", canvasId);
          this.updateViewerEvents(this.grid_item.i);
        }
      },
      async onNewElementsSelected() {
        if (!chartInstances[this.grid_item.i]) return;
        chartMgrInstance.selectElementsInAllPlots(this.getSelectedElements);
      },
      async onNewElementsHighlighted() {
        if (!chartInstances[this.grid_item.i]) return;
        chartMgrInstance.highlightElementsInAllPlots(this.getHighlightedElements);
      },
      async onNewColorByUpdated() {
        if (!chartInstances[this.grid_item.i]) return;
        if(!this.getColorByData.graphicData) {
            chartInstances[this.grid_item.i].clearAllColorBy();
            return;
        } 
        chartInstances[this.grid_item.i].colorElements(this.getColorByData.graphicData.ids,this.getColorByData.graphicData.colors);
      },
      async onNewFilterByUpdated() {
        this.updateChartValues()
      },
      async updateWidget() {
        let postData = {
            widgets: [this.grid_item],
        };
        let update_widgets = await this.$auth.$api.post(
          "/api/widget/update_widgets",
          postData
        );
      },
      updateDisplaySettings() {
          //NOT IMPLEMENTED
      },
      updateViewerEvents(viewerId) {
        //Multi Select Refactor Here....
        chartInstances[viewerId].highlightCheck(true);
        chartInstances[viewerId].registerEvent("element-select", this.elementSelectEvent);
        chartInstances[viewerId].registerEvent("element-highlight", (e) => {this.setHighlightedElements(e);});
        chartInstances[viewerId].registerEvent("scrub-selection", this.elementSelectEvent);
      },
      elementHighlightEvent(ellipseIds) {
        if(ellipseIds === ""){
          this.notebookPropsStore.$patch({selectedElements: []})
        }
        else{
          this.notebookPropsStore.$patch({selectedElements: ellipseIds})
        }
      },
      elementSelectEvent(ellipseIds) {
        if(ellipseIds === ""){
          this.notebookPropsStore.$patch({selectedElements: []})
        }
        else{
          this.notebookPropsStore.$patch({selectedElements: ellipseIds})
        }
      },
      elementSelectEvent(ellipseIds) {
        let elements = (ellipseIds == '' || ellipseIds == undefined) ? [] : ellipseIds
        if (elements == this.lastSelectedIds) return

        this.notebookPropsStore.$patch({ selectedElements: elements});

        const { scrubAction, userPreferenceScrubAction } = this.grid_item.instance_setting.data;

        if (scrubAction === 'Filter' || (scrubAction === "User Preference" && userPreferenceScrubAction === 'Filter')) {
          this.filter(elements, elements.length == 0);
        }
        this.lastSelectedIds = ellipseIds
      },
      async filter(elements, remove) {
        const filterObject = {
          filterType: "Elements",
          isolate: elements.length > 0 ? true : false,
          elements: elements
        };
        let shouldUpdateGlobalFilters = await this.addFilter({ widget: this.grid_item.i, filter: filterObject }, remove);
        if (shouldUpdateGlobalFilters) await this.updateFilterByProperties()
      },
    },
};
</script>
  
<style scoped>
.v-input:deep(.v-select__selection) {
    display: flex;
    align-items: center;
}

.v-input:deep(.v-select__selection-text) {
    font-size: 14px !important;
    width: 170px;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px
}
</style>
  