import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useDataGraphicsStore } from '@/store/DataGraphicsStore.js';
import { useNotebookPropsStore } from '@/store/NotebookPropsStore';
import { useEllipseStore } from '@/store/EllipseStore';
import { getInstance } from "../auth/index";
import router from "@/router";
import { data } from 'jquery';

export const useAssetsStore = defineStore('AssetsStore', () =>{

  //State Variables
  const allAssetsInNotebook = ref({})
  const selectedPageDatasets = ref([])
  const selectedPageDataset = ref(null)
  const selectedPageModels = ref([])
  const selectedPageDrawings = ref([])
  const selectedPageMaps = ref([])
  const selectedPageImages = ref([])
  const selectedPageImagesMap = ref(new Map())
  const selectedPageFiles = ref([])
  const selectedPageFilesMap = ref(new Map())

  //Getters
  const getAllAssetsInNotebook = computed(() => allAssetsInNotebook.value)
  const getSelectedPageDatasets = computed(() => selectedPageDatasets.value)
  const getSelectedPageModels = computed(() => selectedPageModels.value)
  const getSelectedPageDrawings = computed(() => selectedPageDrawings.value)
  const getSelectedPageDataset = computed(() => selectedPageDataset.value)
  const getSelectedPageMaps = computed(() => selectedPageMaps.value)
  const getSelectedPageImages = computed(() => selectedPageImages.value)
  const getSelectedPageImagesMap = computed(() => selectedPageImagesMap.value)
  const getSelectedPageFiles = computed(() => selectedPageFiles.value)
  const getSelectedPageFilesMap = computed(() => selectedPageFilesMap.value)
  
  const getSelectedPageFileAttachments = computed(() => {
    const graphicsStore = useDataGraphicsStore();
    const selectedPageFilesMap = getSelectedPageFilesMap.value; 
    const uniqueAttachments = new Set();
    // Filter out attrData that don't have files attached & get unique files
    graphicsStore.getAttrData.filter(item => item._files && item._files.length > 0)
      .forEach(item => {
        item._files.forEach(fileId => {
          if (selectedPageFilesMap.has(fileId)) { 
            uniqueAttachments.add(selectedPageFilesMap.get(fileId));
          }
        });
      });
    // Convert the Set to an array for table data
    return Array.from(uniqueAttachments);
  })

  const getFilteredFileAttachments = computed(() => {
    const graphicsStore = useDataGraphicsStore();
    const selectedPageFilesMap = getSelectedPageFilesMap.value; 
    const uniqueAttachments = new Set();

  // Access the computed property to ensure reactivity
    const filteredInEllipseIds = graphicsStore.getFilteredInEllipseIds;

    if (filteredInEllipseIds.length > 0){
      graphicsStore.getAttrData.filter(item => item._files && item._files.length > 0 && filteredInEllipseIds.includes(item.ellipseId))
        .forEach(item => {
          item._files.forEach(fileId => {
            if (selectedPageFilesMap.has(fileId)) { 
              uniqueAttachments.add(selectedPageFilesMap.get(fileId));
            }
          });
        });
    } else{
      // Filter out attrData that don't have files attached & get unique files
      graphicsStore.getAttrData.filter(item => item._files && item._files.length > 0)
        .forEach(item => {
          item._files.forEach(fileId => {
            if (selectedPageFilesMap.has(fileId)) { 
              uniqueAttachments.add(selectedPageFilesMap.get(fileId));
            }
          });
        });
    }

    // Convert the Set to an array for table data
    return Array.from(uniqueAttachments);
  });

  const getSelectedPageImageGroups = computed(() => {
    const images = selectedPageImages.value;
    // Use reduce to accumulate all groups into a Set for uniqueness
    const uniqueImageGroups = images.reduce((accumulator, currentImage) => {
      const groups = currentImage.groups || [];
      // Merge the current groups with the accumulator Set
      return new Set([...accumulator, ...groups]);
    }, new Set()); // Initialize the accumulator as an empty Set
    // Convert the Set back into an Array
    return Array.from(uniqueImageGroups);
  });


  //Actions
  async function setAllAssetsInNotebook() {
    const notebookPropsStore = useNotebookPropsStore();
    const ellipseStore = useEllipseStore();
    const authService = getInstance();
    let params = { urlType: router.currentRoute.value.name}
    let assets = await authService.$api.get(
      `/api/notebook/${ellipseStore.getNotebook._id}/get-all-assets`, {params}
    );
    assets = assets.data.data
    allAssetsInNotebook.value = assets
    let selectedPageId = notebookPropsStore.getSelectedPage._id;
    selectedPageDatasets.value = 
      assets.datasets.filter(dataset => dataset.pages.includes(selectedPageId))
    selectedPageModels.value = 
      assets.models.filter(model => model.pages.includes(selectedPageId))
    selectedPageDrawings.value = 
      assets.drawings.filter(drawing => drawing.pages.includes(selectedPageId))
    selectedPageMaps.value =
      assets.maps?.filter(map => map.pages.includes(selectedPageId))
    selectedPageImages.value =
      assets.images?.filter(image => image.pages.includes(selectedPageId))
    //add files
    let fileAttachments = await fetchPageFiles();
    setSelectedPageFileAttachments(fileAttachments);

    await refreshImagesMap(selectedPageImages.value);

    return assets
  }

  async function setSelectedPageDatasets(datasets) {
    selectedPageDatasets.value = datasets
  }

  async function setSelectedPageDataset(dataset) {
    const notebookPropsStore = useNotebookPropsStore();
    const ellipseStore = useEllipseStore();
    const dataGraphicsStore = useDataGraphicsStore();
    const authService = getInstance();

    return new Promise(async (resolve, reject) => {
      if (dataset === null){
        dataGraphicsStore.setAttrData([])
        dataGraphicsStore.setFilteredEllipseIds()
        dataGraphicsStore.setAttrHeaders([])
        selectedPageDataset.value = null
        return 
      }
      else {
        notebookPropsStore.setDatasetLoading(true)
        selectedPageDataset.value = dataset
        let postData = {
          datasetId: dataset._id ? dataset._id : dataset,
          pageId: [notebookPropsStore.getSelectedPage._id],
          notebook: ellipseStore.getNotebook._id,
        };
        try {
          //Patch function to ensure dataset is retrieved. Need to figure out better way to do this.
          async function getDatasetData(postData, iter){
            return new Promise(async (resolve, reject) => {
              let dataset_data = await authService.$api.post(
                `/api/datasets/get_dataset_data/?urlType=${router.currentRoute._value.name}`,
                postData
              );
              if ((dataset_data.data.data[0].data_entry[0].length === 0 || 
                dataset_data.data.data[0].data_header[0].length === 0) && iter < 10){
                setTimeout(async() => {
                  console.log('failed to get data, trying again after 1 sec...')
                  resolve(await getDatasetData(postData, iter + 1));
                }, 1000)
              } 
              else{
                resolve(dataset_data)
              }
            })
          }
          let dataset_data = await getDatasetData(postData, 0)

          dataset_data = dataset_data.data ? dataset_data.data : null;
          if (dataset_data) {
            let data_entries = dataset_data.data[0].data_entry[0];
            let data_header = dataset_data.data[0].data_header[0];
            for (let i in data_entries) {
              let d = data_entries[i];
    
              for (let entry in d) {
                let header = data_header.filter((e) => e._id == entry);
    
                d[header[0].name] = d[header[0]._id];
                delete d[header[0]._id];
              }
              data_entries[i] = d;
            }
    
            data_header.sort((a, b) => (a.name > b.name ? 1 : -1));
            dataset_data.data[0].data_entry = data_entries;
            dataGraphicsStore.setAttrData(dataset_data.data[0].data_entry);
            dataGraphicsStore.setFilteredEllipseIds()
            dataGraphicsStore.setAttrHeaders(data_header);
    
            let unique_header_keys = Array.from(
            dataset_data.data[0].data_entry.reduce((a, c) => {
                Object.keys(c).forEach((e) => a.add(e));
                return a;
              }, new Set())
            );
            unique_header_keys.sort();
            if (dataGraphicsStore.getAttrHeaders.length>0 && dataGraphicsStore.getAttrData.length>0){
              resolve(true)
            }
            else {
              resolve(false)
            } 
          } else {
            dataGraphicsStore.setAttrData([]);
            dataGraphicsStore.setFilteredEllipseIds()
            resolve(false)
          }
        } catch (e) {
          console.log("Err in get_dataset_data", e);
          resolve(e)
        }
      }
      notebookPropsStore.setDatasetLoading(false)
      notebookPropsStore.setLoadingScreen(false);
    })
    
  }

  async function setSelectedPageModels(models) {
    selectedPageModels.value = models
  }

  async function setSelectedPageDrawings(drawings) {
    selectedPageDrawings.value = drawings
  }

  async function setSelectedPageMaps(maps) {
    selectedPageMaps.value = maps
  }

  async function setSelectedPageImages(images) {
    selectedPageImages.value = images;
    await refreshImagesMap(images);
  }

  async function refreshImagesMap(images) {

    const imagesMap = new Map();
    for (let image of images) {
      // Assuming you have a method to fetch the signed URL
      let signedUrl = await fetchSignedUrlForImage(image);
      image.signed_url = signedUrl;

      imagesMap.set(image._id, image);
    }
    selectedPageImagesMap.value = imagesMap;
  }

  async function updateSignedUrlForImage(imageId) {
    const image = selectedPageImagesMap.value.get(imageId);
    if (!image) return; // Image not found in the map

    // Fetch the new signed URL
    let newSignedUrl = await fetchSignedUrlForImage(image);
    image.signed_url = newSignedUrl;

    // Update the map with the new image data
    //selectedPageImagesMap.value.set(imageId, image);
    selectedPageImagesMap.value.set(imageId, {...image});

  }

  async function fetchSignedUrlForImage(image) {
    const authService = getInstance();
    const ellipseStore = useEllipseStore();
    // Implement the logic to fetch the signed URL
    let response = await authService.$api.post(
      `/api/notebook/${ellipseStore.getNotebook._id}/file/get-s3-signed-urls/?urlType=${router.currentRoute._value.name}`, 
      {
        key: image.s3_pointers[0]
      });
    return response.data.url;
  }

  async function fetchPageFiles(){
    const authService = getInstance();
    const ellipseStore = useEllipseStore();
    const getNotebook = ellipseStore.getNotebook;
    
    let rootFolderInfo = await authService.$api.get(
      `/api/notebook/${getNotebook._id}/folder/${getNotebook.rootFolder}/child-folders`
    );

    return rootFolderInfo.data.data;
  }

  async function setSelectedPageFileAttachments(fileAttachments) {
    selectedPageFiles.value = fileAttachments;
    await refreshFileAttachmentsMap(fileAttachments);
  }

  async function refreshFileAttachmentsMap(fileAttachments) {
    const fileAttachmentsMap = new Map();
    for (let fileAttachment of fileAttachments) {
      fileAttachmentsMap.set(fileAttachment._id, fileAttachment);
    }
    selectedPageFilesMap.value = fileAttachmentsMap;
  }

  async function fetchSignedUrlForFile(file) {
    const authService = getInstance();
    const ellipseStore = useEllipseStore();
    // Implement the logic to fetch the signed URL
    //folder, notbook, s3Key

      let notebookId = file.notebook;
      let response = await authService.$api.post(
        `/api/notebook/${notebookId}/file/get-s3-signed-urls/?urlType=${router.currentRoute._value.name}`,
        {
          key: file.s3Key,
        }
      );
    return response.data.url;
  }

  return {
    allAssetsInNotebook,
    selectedPageDatasets,
    selectedPageDataset,
    selectedPageModels,
    selectedPageDrawings,
    selectedPageMaps,
    selectedPageImages,
    selectedPageImagesMap,
    selectedPageFiles,
    selectedPageFilesMap,

    getAllAssetsInNotebook,
    getSelectedPageDatasets,
    getSelectedPageDataset,
    getSelectedPageModels,
    getSelectedPageDrawings,
    getSelectedPageMaps,
    getSelectedPageImages,
    getSelectedPageImageGroups,
    getSelectedPageImagesMap,
    getSelectedPageFileAttachments,
    getFilteredFileAttachments,
    getSelectedPageFiles,
    getSelectedPageFilesMap,

    updateSignedUrlForImage,
    fetchSignedUrlForFile,

    setAllAssetsInNotebook,
    setSelectedPageDatasets,
    setSelectedPageDataset,
    setSelectedPageModels,
    setSelectedPageDrawings,
    setSelectedPageMaps,
    setSelectedPageImages,
    setSelectedPageFileAttachments,
  }
})