<template>
  <v-container>
    <!-- zoom options -->
    <v-btn-group color="background" id="view-toggle" rounded="xl" variant="flat" density="compact">

      <v-btn v-if="showZoom" size="small" class="px-4" icon>
        <v-icon>{{ icons.zoomPercentage }}</v-icon>
        <v-tooltip activator="parent" location="top">
          <span>Zoom Percentage</span>
        </v-tooltip>

        <v-menu left bottom activator="parent">
          <v-list>
            <v-list-item v-for="n in 8" :key="n" @click="zoomByPercentage(n)">
              <v-list-item-title>{{ n * 25 }} %</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>

      <v-btn v-if="showZoom" size="small" class="px-4" icon @click="zoomSelected">
        <v-icon>{{ icons.zoomSelected }}</v-icon>
        <v-tooltip activator="parent" location="top">
          <span>Zoom Selected</span>
        </v-tooltip>
      </v-btn>

      <v-btn v-if="showZoom" size="small" class="px-4" icon @click="zoomExtents">
        <v-icon>{{ icons.zoomExtents }}</v-icon>
        <v-tooltip activator="parent" location="top">
          <span>Zoom Extents</span>
        </v-tooltip>
      </v-btn>

      <v-btn v-if="showFullScreen" size="small" class="px-4" icon @click="fullscreen">
        <v-icon>{{ icons.fullScreen }}</v-icon>
        <v-tooltip activator="parent" location="top">
          <span>FullScreen</span>
        </v-tooltip>
      </v-btn>

      <v-divider v-if="showLayers" vertical></v-divider>
      <v-btn v-if="showLayers" size="small" class="px-5" icon>
        <v-icon>{{ icons.layer }}</v-icon>
        <LayersSettings
          :layers="layers" 
          v-on:layersUpdated="layersUpdated($event)"
          v-on:layersLocked="layersLocked($event)" 
        />
        <v-tooltip activator="parent" location="top">Layers</v-tooltip>
      </v-btn>
      <v-divider v-if="showDownloads | showMultiSelect" vertical></v-divider>

      <v-btn v-if="showMultiSelect" size="small" class="px-4" icon @click="multiSelect">
        <v-icon>{{ icons.multiselect }}</v-icon>
        <v-tooltip activator="parent" location="top">
          <span>Toggle Multi Select</span>
        </v-tooltip>
      </v-btn>

      <v-btn v-if="showDownloads" size="small" class="px-4" icon @click="download">
        <v-icon>{{ icons.download }}</v-icon>
        <v-tooltip activator="parent" location="top">
          <span>{{ lexicon.download}}</span>
        </v-tooltip>
      </v-btn>

    </v-btn-group>
  </v-container>
</template>

<script>
import LayersSettings from "../viewerSettings/layersSettings.vue";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  setup() {
    return {icons, lexicon}
  },
  props: ["showFullScreen", "showZoom", "showDownloads", "showMultiSelect", "showLayers", "layers"],
  methods: {
    zoomByPercentage(n) {
      this.$emit("zoomByPercentage", n * 25);
    },
    zoomExtents() {
      this.$emit("zoomExtents", {});
    },
    zoomSelected() {
      this.$emit("zoomSelected", {});
    },
    download() {
      this.$emit("download", {});
    },
    fullscreen() {
      this.$emit("fullscreen", {});
    },
    multiSelect() {
      this.$emit("multiSelect", {});
    },
    layersUpdated(e) {
      this.$emit("layersUpdated", e);
    },
    layersLocked(e) {
      this.$emit("layersLocked", e);
    },
  },
  components: {
    LayersSettings,
  },
};
</script>

<style scoped></style>
