<template>

  <div v-if="!getLoadingScreen" style="height: 100%">

    <!-- Save Button. -->
    <span class="savBtnStyle">
      <div v-if="getNotebook">
        <div v-if="getNotebook.userPermissions">
          <div v-if="getNotebook.userPermissions.permissions >= 400">
            <div v-if="getEditMode">
              <!-- Edit Mode - View / Save Battery button. -->
              <v-btn-toggle density="compact" class="rounded-pill" elevation="3">
                <!-- Back to View Mode Button. -->
                <v-hover v-slot="{ isHovering, props }">
                  <v-btn :class="isHovering ? 'bg-secondary' : 'bg-background'" v-bind="props" icon size="large"
                    @click="onClickEdit">
                    <v-tooltip activator="parent" location="top">Preview Layout</v-tooltip>
                    <v-icon :color="isHovering ? 'white' : 'secondary'" size="23">
                      {{ icons.visible }}
                    </v-icon>
                  </v-btn>
                </v-hover>
                <!-- Save and Return to View Mode Button. -->
                <v-hover v-slot="{ isHovering, props }">
                  <v-btn v-bind="props" elevation="2" :class="isHovering ? 'bg-secondary' : 'bg-primary'" icon
                    size="large" @click="saveCurrentState(true)" :loading="saveCurrentStateLoadingAndDisabled"
                    :disabled="saveCurrentStateLoadingAndDisabled">
                    <v-icon :color="isHovering ? 'white' : 'background'" size="23">
                      {{ icons.save }}
                    </v-icon>
                    <v-tooltip activator="parent" location="top">Save Layout</v-tooltip>
                  </v-btn>
                </v-hover>
              </v-btn-toggle>
            </div>
            <!-- Enter Edit Mode Button. -->
            <div v-if="!getEditMode && getIsInFullMode">
              <v-btn-toggle density="compact" class="rounded-pill" elevation="3">
                <v-hover v-slot="{ isHovering, props }">
                  <v-btn v-bind="props" icon size="large" :class="isHovering ? 'bg-warning' : 'bg-background'"
                    @click="onClickEdit">
                    <v-icon :color="isHovering ? 'darkSlate' : 'darkSlate'" size="default">
                      {{ icons.edit }}
                    </v-icon>
                    <v-tooltip activator="parent" location="top">Edit Layout</v-tooltip>
                  </v-btn>
                </v-hover>
                <v-hover v-slot="{ isHovering, props }">
                  <v-btn v-bind="props" elevation="2" icon size="large"
                    :class="isHovering ? 'bg-primary' : 'bg-background'" @click="saveCurrentState(true)"
                    :loading="saveCurrentStateLoadingAndDisabled" :disabled="saveCurrentStateLoadingAndDisabled">
                    <v-icon :color="isHovering ? 'background' : 'secondary'" size="default">
                      {{ icons.save }}
                    </v-icon>
                    <v-tooltip activator="parent" location="top">Save Page</v-tooltip>
                  </v-btn>
                </v-hover>

              </v-btn-toggle>
            </div>
          </div>
        </div>
      </div>
    </span>

    <!-- Widget Grid Layout. -->
    <div style="height: 100%">
      <GridLayout />
    </div>
  </div>

  <!-- Loading Circle. -->
  <div v-if="getLoadingScreen" style="height: 100%">
    <v-card class="loadingStyleClass">
      <ProgressCircular>Loading</ProgressCircular>
    </v-card>
  </div>

  <!-- No Widgets Message. -->
  <template v-if="JSON.stringify(getSelectedPage) !== '{}'">
    <NoDataWarning :show="getWidgets.length === 0 && !getLoadingScreen" messages.widgetEmpty.icon>{{
      messages.widgetEmpty.memo }}{{ lexicon.widget.plural }}</NoDataWarning>
  </template>

  <!-- Access Denied Message. -->
  <!-- <template v-else>
    <NoDataWarning :show="true">
      Access denied, you don’t have permission to access this page.
    </NoDataWarning>
  </template> -->

  <!-- Snackbar for XXXX mesages. -->
  <v-snackbar v-model="snackbar" :timeout="timeout" absolute location="bottom" :color="snackbarColor">
    <div class="text-center">
      {{ snackbarMessage }}
    </div>
  </v-snackbar>
</template>

<script>
import html2canvas from 'html2canvas';
import filesaver from "file-saver";
import * as utils from "@/utilities/index.js"
import GridLayout from "@/components/GridLayout.vue";
import NoDataWarning from "../components/ui/NoDataWarning.vue";
import ProgressCircular from "../components/ui/ProgressCircular.vue";
import * as icons from "@/utilities/EllipseIcons.js";

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";

import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import { useCommentsStore } from "@/store/CommentsStore.js";
import { useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import { useAssetsStore } from "@/store/AssetsStore.js";
import { useEllipseStore } from "@/store/EllipseStore.js";

export default {
  inheritAttrs: false,
  components: {
    GridLayout,
    NoDataWarning,
    ProgressCircular,
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const commentsStore = useCommentsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const ellipseStore = useEllipseStore()
    const {
      getEditMode,
      getWidgets,
      getwidgetIDsToUpdate,
      getWidgetsToDelete,
      getGlobalPropertyUpdated,
      getGlobalProperty,
      getSelectedPage,
      getSelectedPageStateId,
      getPagesStructuredData,
      getAllPageStates,
      getLoadingScreen,
    } = storeToRefs(notebookPropsStore)
    const {
      getSelectedCommentFromFeed,
      setSelectedCommentFromFeed,
    } = storeToRefs(commentsStore)
    const {
      getAllAssetsInNotebook,
      getSelectedPageDataset
    } = storeToRefs(assetsStore)
    const {
      getNotebook,
    } = storeToRefs(ellipseStore)
    const {
      toggleEditMode,
      setWidgets,
      setwidgetIDsToUpdate,
      setGlobalProperty,
      setSelectedPage,
      setPagesStructuredData,
      updateAllPageStates,
      setLoadingScreen
    } = notebookPropsStore
    const {
      setColorByData
    } = dataGraphicsStore
    const {
      setAllAssetsInNotebook,
      setSelectedPageDataset
    } = assetsStore
    const {
      setSelectedLayoutId,
      setNotebook
    } = ellipseStore
    return {
      notebookPropsStore, commentsStore, dataGraphicsStore, assetsStore, ellipseStore,
      getEditMode, getWidgets, getSelectedCommentFromFeed, getAllPageStates, getNotebook,
      getwidgetIDsToUpdate, getWidgetsToDelete, getGlobalPropertyUpdated, getAllAssetsInNotebook,
      getSelectedPageDataset, getSelectedPage, getSelectedPageStateId, getPagesStructuredData,
      getLoadingScreen,
      setwidgetIDsToUpdate, getGlobalProperty, setGlobalProperty, setColorByData, setWidgets, toggleEditMode,
      setSelectedCommentFromFeed, setAllAssetsInNotebook, setSelectedPageDataset, setSelectedPage,
      setPagesStructuredData, updateAllPageStates, setNotebook, setSelectedLayoutId, setLoadingScreen, icons, lexicon, messages
    }
  },
  data() {
    return {
      saveCurrentStateLoadingAndDisabled: false,
      snackbar: false,
      timeout: 2000,
      snackbarColor: "",
      snackbarMessage: "",
      isFullscreen: true,
    };
  },
  computed: {
    getIsInFullMode() {
      return this.isFullscreen;
    },
  },
  mounted() {
    this.setNavButtons();
  },
  async created() {
    this.setLoadingScreen(true);
    //set notebook
    let setNotebookData = { id: this.$route.params.id, urlType: this.$route.name }
    await this.setNotebook(setNotebookData);
    if (this.getNotebook) {
      //get all the pages
      await this.setPagesStructuredData(this.$route.params.id);
      await this.initialPageLoadingSteps();
    }
  },
  methods: {
    async getWidgetsMethod() {
      //get widgets by pageStateId,set widgets,and trigger layout page
      await this.$auth.$api.get(
        `/api/widget/${this.getSelectedPageStateId}/get_widgets_by_pagestate`
      )
        .then(async (response) => {
          await this.setWidgets(response.data.data.widgets)
          let postData = {
            global_setting: this.getGlobalProperty.global_setting,
          };
          await this.$auth.$api.post(
            `/api/pagestate/${this.getSelectedPageStateId}/update_pagestate_global_setting`,
            postData
          )
        })
        .catch((e) => {
          console.log("Error", e);
          this.setWidgets([])
        });
    },
    onClickEdit() {
      this.toggleEditMode();
      this.notebookPropsStore.$patch({ selectedWidget: null })
      this.notebookPropsStore.$patch({ selectedNavigationTab: 'globalProperties' })
    },
    async saveCurrentState(value) {
      this.saveCurrentStateLoadingAndDisabled = value;
      await utils.saveCurrentState()

      this.snackbarColor = "primary";
      this.snackbarMessage = "Page Updated";
      this.snackbar = true;

      this.notebookPropsStore.$patch({ selectedNavigationTab: "globalProperties" })
      this.saveCurrentStateLoadingAndDisabled = false;
    },

    async initialPageLoadingSteps() {
      let urlPageId = this.$route.params.pageId;
      //Load notebook for the first time
      if (urlPageId === undefined) {
        this.$router
          .push(
            "/notebook/" +
            this.getNotebook._id +
            "/page/" +
            this.getPagesStructuredData[0]._id
          )
          .catch((err) => { });
        await this.setSelectedPage(this.getPagesStructuredData[0])

      } //Notebook has been loaded before
      else {
        //find the selected page from the pages structured data
        for (let i = 0; i < this.getPagesStructuredData.length; i++) {
          //console.log("pre");
          if (
            this.getPagesStructuredData[i]._id === this.$route.params.pageId
          ) {
            //console.log("in 1");
            await this.setSelectedPage(this.getPagesStructuredData[i])
            break;
          }
          // Iterate over the children (sub pages) of each page
          //and check if they are the selected page
          if (this.getPagesStructuredData[i].childNodes.length > 0) {
            //console.log("post");
            for (
              let c = 0;
              c < this.getPagesStructuredData[i].childNodes.length;
              c++
            ) {
              if (
                this.getPagesStructuredData[i].childNodes[c]._id ===
                this.$route.params.pageId
              ) {
                //console.log("in 2");
                await this.setSelectedPage(this.getPagesStructuredData[i].childNodes[c])
                break;
              }
            }
          }
        }
      }
      if (JSON.stringify(this.getSelectedPage) !== "{}") {
        //store layoutId
        let layoutId = this.getSelectedPage.layout;
        await this.setSelectedLayoutId(layoutId)

        let state = this.getAllPageStates.find(
          (p) => p.page === this.getSelectedPage._id
        );

        //set selected page state id
        this.notebookPropsStore.$patch({
          selectedPageStateId: state._id,
        })

        //get and set all assets
        await this.setAllAssetsInNotebook()

        //setting up global properties
        await this.setGlobalProperty(state)

        //setting up global color by gradient
        this.setWidgets(state.widgets)

        //set the selected dataset
        if (state.selectedDataset) {
          await this.setSelectedPageDataset(state.selectedDataset)
        }
        else {
          if (this.getAllAssetsInNotebook.datasets.length > 0) {
            await this.setSelectedPageDataset(this.getAllAssetsInNotebook.datasets[0])
          }
        }

        //Change navigation tab to comments if selected comment from feed
        if (this.getSelectedCommentFromFeed) {
          this.notebookPropsStore.$patch({
            selectedNavigationTab: 'comments'
          })
        }

        //Change navigation tab to global sources if no assets in notebook
        if (this.getAllAssetsInNotebook.datasets.length === 0 &&
          this.getAllAssetsInNotebook.drawings.length === 0 &&
          this.getAllAssetsInNotebook.models.length === 0) {
          this.toggleEditMode();
          this.notebookPropsStore.$patch({
            selectedNavigationTab: 'globalSources'
          })
        }
      }
      this.setLoadingScreen(false);
    },
    toggleScreenMode() {
      if (this.isFullscreen) {
        this.openFullscreen();
      }
      else {
        this.closeFullscreen();
      }
    },
    openFullscreen() {
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
      this.isFullscreen = false;
      this.toggleLeftNavigationDrawer = true;
    },
    closeFullscreen() {

      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
      this.isFullscreen = true;
      this.toggleLeftNavigationDrawer = false;
    },
    screenCaptureView() {
      var element = document.getElementById('dashboard');

      let filename = this.getNotebook.name + "-" + this.getSelectedPage.name + "-" +utils.timeStamp();

      html2canvas(element, {
        scale: 2
      }).then(canvas => {

        filesaver.saveAs(canvas.toDataURL(), filename);
      }).catch((error) => {
        alert("Image failed to download")
      });

    },
    setNavButtons() {
      const fullScreen = document.getElementById("fullscreen");
      fullScreen.addEventListener("click", this.toggleScreenMode);

      const imgCapture = document.getElementById("imagecapture");
      imgCapture.addEventListener("click", this.screenCaptureView);
    },

  },
  async unmounted() {
    //reset all stores to initial state when leaving notebook
    this.notebookPropsStore.$reset()
    this.commentsStore.$reset()
    this.assetsStore.$reset()
    this.dataGraphicsStore.$reset()

    this.ellipseStore.$patch({
      selectedlayoutId: null,
      notebook: null
    })
  },
};
</script>

<style scoped>
.savBtnStyle {
  position: absolute;
  z-index: 20;
  bottom: 25px;
  right: 35px;
}

.loadingStyleClass {
  height: 100%;
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
