<template>
  <div>
    <v-hover v-slot:default="{ isHovering, props }">
    <v-card v-bind="props" class="rounded-lg" @click="onClickCard()" :elevation="isHovering ? 5 : 2">
      <div class="pa-2">
        <div class="d-flex">
          <div :style="`position:relative;`">
            <v-avatar 
            class="rounded-lg" 
            color="darkGrey" 
            tile
            style="height: 120px; width: 130px"
            >
              <v-img src="../assets/imgs/notebookCardPlaceholder.png" cover></v-img>
            </v-avatar>
          </div>
          <div class="pl-2">
            <div class="d-flex pb-1">
              <v-icon size="x-small" color="darkGrey">{{ lexicon.page.icon }}</v-icon>
              <div class="item-property">
                <span v-if="publicPages().length">
                  {{ publicPages().length }} Pages</span><span v-else>0 Pages</span>
              </div>
            </div>

            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <div v-bind="props" style="width:100%;">
                  <div v-for="page in filterList(7)" :key="page.id" class="item-property">
                    <small class="text-truncate"> - {{ page.name }} </small>
                  </div>
                  <span v-if="publicPages().length>7" class="item-property">...</span>
                </div>
              </template>
              <!-- See Properties Full Name Tooltip. -->
              <div>
                <v-row v-for="pg in publicPages()" :key="pg.id" class="d-flex">
              <v-icon size="small" color="white">{{ lexicon.page.icon }}</v-icon><span> &nbsp; {{ pg.name }}<br></span>
            </v-row>
           </div>
            </v-tooltip>
          </div>
        </div>
        <div class="pl-2">
          <div class="pt-2 font-weight-bold text-body text-truncate">
            <span>{{ template.name }}</span>
          </div>
          <span class="font-weight-light text-caption">
            Author: <b>{{ template.created_by.name }}</b></span>
          <div class="pt-1 font-weight-light text-caption">
            <span>
              Created:
              {{ $filters.momentTimestampDDMMYYYY(template.createdAt) }}
            </span>
          </div>
        </div>

        <div class="d-flex">
          <v-spacer></v-spacer>
          <span>
            <v-menu offset-y>
              <template v-slot:activator="{ props}">
                <v-btn v-bind="props" icon size="x-small" variant="text">
                  <v-icon size="small" color="darkSlate">{{ icons.menu }}</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="clickOnEditTemplate(template)">
                  <template v-slot:prepend>
                    <v-icon>{{ icons.rename }}</v-icon>
                  </template>
                  <v-list-item-title>Rename</v-list-item-title>
                </v-list-item>
                <v-list-item @click="clickOnDeleteTemplate(template)">
                  <template v-slot:prepend>
                    <v-icon>{{ icons.remove }}</v-icon>
                  </template>
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </div>
     
        <AreYourSurePopUp 
          :dialog="deleteTemplateDialog"
          @toggle="deleteTemplateDialog=!deleteTemplateDialog"
          :onClick="deleteTemplate"
          :loading="deleteTemplateLoading"
          >Are you sure you want to delete the Template?
        </AreYourSurePopUp>

        <v-dialog v-model="createNewNotebookDialog" width="500">
          <v-card>
            <v-card-title> New Notebook </v-card-title>
            <v-card-subtitle> Based on Template: <b>{{ template.name }}</b> </v-card-subtitle>
            <v-text-field hide-details variant="outlined" class="popUpTextField pt-4" v-model="notebookName"
              label="Notebook Name"></v-text-field>
            <v-textarea hide-details variant="outlined" class="popUpTextField" v-model="notebookDescription" rows="2"
              label="Notebook Description"></v-textarea>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="darkGrey" class="text-white" @click="cancelCreateNotebook()"> Cancel
              </v-btn>
              <v-btn :disabled="!notebookName" :loading="createNotebookLoading" color="primary"
                @click="createNewNotebook()"> Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      <EditData
        :dialog="editTemplateDialog"
        @toggle="editTemplateDialog=!editTemplateDialog"
        @actionClicked="editTemplate"
        :loading="editTemplateLoading"
        :textInput="template.name"
        >Rename Template
      </EditData>
      </div>
    </v-card>
    </v-hover>
  </div>
</template>

<script>
import AreYourSurePopUp from "../components/popUps/AreYouSure.vue";
import EditData from "../components/popUps/EditData.vue";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useEllipseStore } from "@/store/EllipseStore.js";

export default { 
  components: {AreYourSurePopUp,EditData},
  props: ["template"],
  setup(){
    const ellipseStore = useEllipseStore()
    const {
      getNotebooks,
      getTemplates,
    } = storeToRefs(ellipseStore)
    const {
      setNotebooks,
      setTemplates
    } = ellipseStore
    return {
      getNotebooks, 
      getTemplates,
      setNotebooks, 
      setTemplates, 
      lexicon, 
      icons
    }
  },
  data() {
    return {
      deleteTemplateDialog: false,
      deleteTemplateLoading: false,
      createNewNotebookDialog: false,
      notebookName: "",
      notebookDescription: "",
      createNotebookLoading: false,
      editedItemIndex:-1,
      editTemplateDialog:false,
      editTemplateLoading:false,
      editedName:"",

    };
  },
  computed: {},
  created(){
  },
  methods: {
    onClickCard() {
      this.createNewNotebookDialog = true;
    },
    publicPages() {
      var pages = [];
      this.template.pages.forEach(page => {
        if (page.isPublic) pages.push(page);
      });

      return pages;
    },
    filterList(total) {
      var tempPages = this.publicPages();
      var count = tempPages.length;
      if (count > total) count = total-1;
      var pages = [];
      for (let i = 0; i < count; i++) {
        pages.push(tempPages[i]);
      }
      return pages;
    },
    async createNewNotebook() {
      this.createNotebookLoading = true;
      let postData = {
        name: this.notebookName,
        description: this.notebookDescription,
        template_id: this.template._id,
      };

      let oldnotebooks = this.getNotebooks;
      // console.log("postData",postData);
      await this.$auth.$api.post("/api/notebooks/new", postData);
      await this.setNotebooks()
      this.createNotebookLoading = false;
      this.createNewNotebookDialog = false;

      let newnotebooks = this.getNotebooks;
      var notebook = newnotebooks.filter(function (obj) { return oldnotebooks.indexOf(obj) == -1; });

      this.$router.push(`/notebook/${notebook[0]._id}`).catch(err => { });
    },
    async cancelCreateNotebook() {
      this.createNewNotebookDialog = false;
      this.notebookName = "";
      this.notebookDescription = "";
    },
    clickOnDeleteTemplate(item) {
      this.editedItemIndex = this.getTemplates.indexOf(item);
      this.deleteTemplateDialog = true;
    },
    async deleteTemplate() {
      this.deleteTemplateLoading = true;
      let route = `/api/template/${this.template._id}/delete_template`;
      await this.$auth.$api.delete(route)
        .then(response => {
          console.log("response", response);
        })
        .catch((e) => {
          console.log("Error", e);
        });
      // await $.ajax({
      //   url: route,
      //   type: "DELETE",
      //   dataType: "json",
      //   success: function (response) {
      //     console.log("success", response);
      //   },
      //   error: function (response) {
      //     console.log("error", response);
      //   },
      // });
      this.getTemplates.splice(this.editedItemIndex, 1);
      this.deleteTemplateLoading = false;
      this.deleteTemplateDialog = false;
    },
    async clickOnEditTemplate(template){
      this.editedName = template.name;
      this.editTemplateDialog = true;
    },
    async editTemplate(newName){
      this.editTemplateLoading = true;

      let postBody = {
        name: newName,
      };
      let updated = await this.$auth.$api.post(
        `/api/template/${this.template._id}/edit-info`,
        postBody
      );
      await this.setTemplates()

      this.editTemplateDialog = false;
      this.editTemplateLoading = false;
    }
  },
};
</script>

<style scoped>
.item-property {
  font-size: 12px;
  padding-left: 4px;
  font-weight: 300;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  color: rgb(var(--v-theme-darkGrey));
}
</style>
