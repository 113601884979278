<template>
  <div>
    <v-hover v-slot:default="{ isHovering, props }">
      <v-card v-bind="props" class="rounded-lg" @click="onClickCard" :elevation="isHovering ? 5 : 2">
        <div class="pa-2">
          <div class="d-flex">
            <div :style="`position:relative;`">
              <v-avatar 
              class="rounded-lg" 
              color="darkGrey" 
              style="height: 120px; width: 130px"
              >
                <v-img src="../assets/imgs/notebookCardPlaceholder.png" cover></v-img>
              </v-avatar>
            </div>

            <div class="pl-2">
              <div class="d-flex pb-1">
                <v-icon  size="x-small" color="darkGrey">{{ lexicon.widget.icon }}</v-icon>
                <div class="item-property" >
                  <span v-if="layout.widgets.length">
                    {{ layout.widgets.length }} {{lexicon.widget.plural}}</span><span v-else>0 {{lexicon.widget.plural}}</span>
                </div>
              </div>

              <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                  <div v-bind="props" style="width:100%;" >
                    <div v-for="widget in filterList(7)" :key="widget._id" class="item-property">
                      <small class="text-truncate"> - {{ widget.content }} </small>
                    </div>
                    <span v-if="layout.widgets.length>7" class="item-property">...</span>
                  </div>
                </template>
                <!-- See Properties Full Name Tooltip. -->
                <div>
                  <v-row v-for="wdgt in layout.widgets" :key="wdgt.id" class="d-flex">
                    <v-icon size="small" color="white">{{ lexicon.widget.icon }}</v-icon><span> &nbsp; {{ wdgt.content }}<br></span>
                  </v-row>
                </div>
              </v-tooltip>
            </div>
          </div>

          <div class="pl-2">
            <div class="pt-2 font-weight-bold text-body text-truncate">
              <span>{{ layout.name }}</span>
            </div>
            <div class="pt-1 font-weight-light text-caption">
              
              <span>
                {{lexicon.created}} {{ $filters.momentTimestampDDMMYYYY(layout.createdAt) }}
              </span>
            </div>
          </div>

          <div class="d-flex">
            <v-spacer></v-spacer>
            <span>
              <v-menu offset-y>
                <template v-slot:activator="{ props}">
                  <v-btn v-bind="props" size="x-small" icon  variant="text">
                    <v-icon size="small" color="darkSlate">{{ icons.menu }}</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="clickOnRenameLayout(layout)">
                    <template v-slot:prepend>
                      <v-icon>{{ icons.rename }}</v-icon>
                    </template>
                    <v-list-item-title>{{ lexicon.rename }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="clickOnEditLayoutWidgets(layout)">
                    <template v-slot:prepend>
                      <v-icon>{{ lexicon.layout.icon }}</v-icon>
                    </template>
                    <v-list-item-title>{{ messages.layoutEdit }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="clickOnDeleteLayout(layout)">
                    <template v-slot:prepend>
                      <v-icon>{{ icons.remove}}</v-icon>
                    </template>
                    <v-list-item-title>{{ lexicon.del }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
          </div>

        </div>
      </v-card>
    </v-hover>
    <AreYourSurePopUp 
      :dialog="deleteLayoutDialog"
      @toggle="deleteLayoutDialog=!deleteLayoutDialog"
      :onClick="deleteLayout"
      :loading="deleteLayoutLoading"
      > {{ messages.layoutDelete }}
    </AreYourSurePopUp>

    <v-dialog v-model="createNewNotebookDialog" width="500">
      <v-card>
        <v-card-title class="">{{ messages.notebookNew }}</v-card-title>
        <v-card-subtitle >{{messages.layoutNotebook}}<b>{{ layout.name }}</b> </v-card-subtitle>
        <v-text-field hide-details variant="outlined" class="popUpTextField pt-4" v-model="notebookName"
          :label="messages.notebookName"></v-text-field>
        <v-textarea hide-details variant="outlined" class="popUpTextField" v-model="notebookDescription" rows="2"
          :label="messages.notebookDescription"></v-textarea>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darkGrey" class="text-white" @click="cancelCreateNotebook()"> {{ lexicon.cancel }}
          </v-btn>
          <v-btn :disabled="!notebookName" :loading="createNotebookLoading" color="primary"
            @click="createNewNotebook()"> {{ lexicon.create }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <EditData
      :dialog="renameLayoutDialog"
      @toggle="renameLayoutDialog = !renameLayoutDialog"
      @actionClicked="renameLayout"
      :loading="renameLayoutLoading"
      :textInput="layout.name"
      >{{messages.layoutRename}}
    </EditData>

    <v-dialog persistent v-model="editLayoutDialog" :retain-focus="false" width="80%">
      <LayoutEditorDialog 
        :layoutToEdit="layoutToEdit"
        @toggle1="editLayoutDialog=!editLayoutDialog"
        >
      </LayoutEditorDialog>
    </v-dialog>
  </div>
</template>
  
<script>
import AreYourSurePopUp from "../components/popUps/AreYouSure.vue";
import EditData from "../components/popUps/EditData.vue";
import LayoutEditorDialog from '../components/LayoutEditorDialog.vue';
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useEllipseStore} from "@/store/EllipseStore.js";

export default {
  components: {AreYourSurePopUp, EditData, LayoutEditorDialog},
  props: ["layout"],
  setup(){
    const ellipseStore = useEllipseStore();
    const { 
      getNotebooks,
      getLayouts, 
      getUser,
    } = storeToRefs(ellipseStore);
    const {
      setLayouts,
      setNotebooks,
    } = ellipseStore;
    return {
      getNotebooks,
      getLayouts,
      getUser,
      setLayouts,
      setNotebooks,
      lexicon,
      messages,
      icons
    };
  },
  data() {
    return {
    deleteLayoutDialog: false,
    deleteLayoutLoading: false,
    editedItemIndex: -1,
    layouts: [],
    createNewNotebookDialog: false,
    notebookName:'',
    notebookDescription:'',
    createNotebookLoading:false,
    layoutToEdit:{},

    renameLayoutDialog:false,
    renameLayoutLoading:false,
    editLayoutDialog: false
    };
  },
  computed: {},
  methods: {
  onClickCard() {
    this.createNewNotebookDialog = true;
  },
  clickOnDeleteLayout(item) {
    this.editedItemIndex = this.getLayouts.indexOf(item);
    this.deleteLayoutDialog = true;
  },
  async cancelCreateNotebook() {
      this.createNewNotebookDialog = false;
      this.notebookName = "";
      this.notebookDescription = "";
  },
  async createNewNotebook() {
    this.createNotebookLoading = true;
    let postData = {
      name: this.notebookName,
      description: this.notebookDescription,
      layout: this.layout,
    };

    let oldnotebooks = this.getNotebooks;
    // console.log("postData",postData);
    await this.$auth.$api.post("/api/notebooks/new", postData);
    await this.setNotebooks()
    this.createNotebookLoading = false;
    this.createNewNotebookDialog = false;
    
    let newnotebooks = this.getNotebooks;
    var notebook = newnotebooks.filter(function(obj) { return oldnotebooks.indexOf(obj) == -1; });

    this.$router.push(`/notebook/${notebook[0]._id}`).catch(err => {});
  },
  async deleteLayout() {
    this.deleteLayoutLoading = true;
    let route = `/api/layout/${this.layout._id}/delete_layout`;
    await this.$auth.$api.delete(route)
        .then(response => {
          console.log("response", response);
        })
        .catch((e) => {
          console.log("Error", e);
        });
    // await $.ajax({
    //   url: route,
    //   type: "DELETE",
    //   dataType: "json",
    //   success: function (response) {
    //     console.log("success", response);
    //   },
    //   error: function (response) {
    //     console.log("error", response);
    //   },
    // });
    this.getLayouts.splice(this.editedItemIndex, 1);
    this.deleteLayoutLoading = false;
    this.deleteLayoutDialog = false;
  },

  async clickOnRenameLayout(){
    this.renameLayoutDialog = true;
  },

  filterList(total) {
    // console.log(this.layout.widgets)
    var tempWidgets = this.layout.widgets;
    var count = tempWidgets.length;
    if (count > total) count = total-1;
    var widgets = [];
    for (let i = 0; i < count; i++) {
      widgets.push(tempWidgets[i]);
    }
    return widgets;
  },
  async deleteLayout() {
    this.deleteLayoutLoading = true;
    let route = `/api/layout/${this.layout._id}/delete_layout`;
    await this.$auth.$api.delete(route)
      .then(response => {
        console.log("response", response);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    // await $.ajax({
    //   url: route,
    //   type: "DELETE",
    //   dataType: "json",
    //   success: function (response) {
    //     console.log("success", response);
    //   },
    //   error: function (response) {
    //     console.log("error", response);
    //   },
    // });
    this.deleteLayoutLoading = false;
    this.deleteLayoutDialog = false;
  },

  async renameLayout(newLayoutName){
    this.renameLayoutLoading = true;

    let postBody = {
      name: newLayoutName,
    };
    let updated = await this.$auth.$api.post(
      `/api/layout/${this.layout._id}/edit-info`,
      postBody
    );
    await this.setLayouts(this.getUser._id)

    this.renameLayoutDialog = false;
    this.reanmeLayoutLoading = false;
  },
    
    async clickOnEditLayoutWidgets(layout){
      this.layoutToEdit = layout;
      this.editLayoutDialog = true;
    },
  },
};
</script>
  
<style scoped>
  .item-property {
    font-size: 12px;
    padding-left: 4px;
    font-weight: 300;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    color: rgb(var(--v-theme-darkGrey));
  }
</style>
  