<template>
    <div class="widgetPadding pa-0" style="height:100%">
        <!-- Corner Icons for View and Edit Mode -->
        <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
        <!-- Loading Spinner -->
        <Spinner :show="(getAttrData.length === 0 && getSelectedPageDatasets.length != 0) || datasetSwitchingLoading">
        </Spinner>
        <!-- No Dataset Warning -->
        <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
        <!-- Widget Title Header -->
        <WidgetTitle v-if="grid_item.name && grid_item.content" :item="grid_item"></WidgetTitle>

        <!-- Header Description -->
        <v-container class="px-2 pt-2 pb-0 d-flex justify-space-between">
            <h5 v-if="grid_item.instance_setting.data" class="WidgetSubHeader">
                {{ this.grid_item.instance_setting.data.selectedAttr }}
            </h5>

            <h5 v-if="grid_item.instance_setting.data" class="WidgetSubHeader pr-1">
                       
                <small>
                    Sum by
                <strong v-if="this.grid_item.instance_setting.data.displaySettings.summaryAttribute != 'None'">
                        {{ this.grid_item.instance_setting.data.displaySettings.summaryAttribute }}
                </strong>
                <strong v-else>
                    Count
                </strong>
            </small>
            </h5>

        </v-container>

        <!-- Chart -->
        <div ref="chartCanvas" :id="canvasId()" style="height: 100%; width:100%"></div>

    </div>
</template>
  
<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../ui/WidgetTitle.vue";
import chartMgrInstance from "@ttcorestudio/viewer_data/library_files/PlotMgr.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import {useAssetsStore} from "@/store/AssetsStore.js";

//globals
//these variables are shared accross all component instances
let chartManager = {};
let chartInstances = {};
let chartCount = 0;

export default {
    components: { Spinner, NoDataWarning, WidgetIcon,WidgetTitle },
    props: ["grid_item"],
    setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getHighlightedElements,
      getSelectedElements,
      getGlobalProperty,
    } = storeToRefs(notebookPropsStore)
    const {
      getColorByData,
      getFilterByData,
      getAttrData,
      getAttrHeadersNumerical,
      getAttrHeaderOptions,
      getUniqueAttrTotalByHeader 
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets,
      getSelectedPageDataset
    } = storeToRefs(assetsStore)
    const {
      setHighlightedElements,
      removeFromSelectedElements,
      addToSelectedElements,
      addRangeToSelectedElements
    } = notebookPropsStore
    return {notebookPropsStore, dataGraphicsStore, assetsStore,
    getHighlightedElements, getGlobalProperty, getSelectedElements, getSelectedPageDataset,
    getSelectedPageDatasets, getEditMode, getColorByData, getFilterByData, getAttrData, getAttrHeadersNumerical, getAttrHeaderOptions,
    setHighlightedElements, removeFromSelectedElements, addToSelectedElements, addRangeToSelectedElements, getUniqueAttrTotalByHeader, 
    }
  },
    data() {
        return {
            updatingWidgets: false,
            datasetSwitchingLoading: false,
            selectedAttr: "None",
            selectedAttrType: "string",
            selectedAttrValues: [],
            selectedAttrIds: [],
            summaryAttribute: "None",
            summaryKey:undefined,
            summaryType:"",
            isFlipped:true,
            gridX:true,
            gridY:false,
            axisX:true,
            axisY:false,
            axisAngle:0,
            respondToFiltering: false,
      filter:{
        filterType: "Range",
        field: "None",
        range: this.range,
      },
        };
    },
    computed: {
        widgetIcon() {
            let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
            return result;
        },
        displaySettings(){
            if (!this.grid_item.instance_setting.data) return;
            return this.grid_item.instance_setting.data.displaySettings;
        }
    },
    created() {
        this.verifyInstanceSettings();
    },
    async mounted() {
        this.verifyInstanceSettings();
        await this.creatingInstanceAndCanvas();

    setTimeout(async () => {
           await this.updateChartValues();
    }, 500);
    
    //Listen for changes in the notebookPropsStore
    this.notebookPropsStore.$onAction(({name, args}) => {
      if (name === 'setHighlightedElements') {
        this.onNewElementsHighlighted()
      }
      if (name === 'setGlobalHighlightColor'){
        this.globalHighlightColorEvent(args[0])
      }
      if (name === 'setGlobalSelectionColor'){
        this.globalSelectionColorEvent(args[0])
      }
      if (name === 'setGlobalSignificantDigits'){
        this.globalSignificantDigitsEvent(args[0])
      }
    })
    //Listen for changes in the dataGraphicsStore
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateColorByProperties') {
          this.onNewColorByUpdated()
        }
        if (name === 'updateFilterByProperties') {
          this.onNewFilterByUpdated()
        }
      })
    })
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.newDatasetLoadStartedEvent()
        }
      })
    })


    },
    beforeUnmount() {
        if (chartInstances[this.grid_item.i]) {
            chartMgrInstance.clearPlotByName('bar', this.grid_item.i);
            chartInstances[this.grid_item.i].destroy();
            delete chartInstances[this.grid_item.i];
        }
    },
    watch: {
        getSelectedElements: {
            deep: true,
            handler() {
                if (chartInstances[this.grid_item.i]) {
                    chartMgrInstance.selectElementsInAllPlots(this.getSelectedElements);
                }
            }
        },
        "grid_item.instance_setting.data.displaySettings.isFlipped": function () {
            this.updateChartValues();
        },
        "grid_item.instance_setting.data.selectedAttr": function () {
            this.selectedAttrChanged();
        },
        "grid_item.instance_setting.data.displaySettings.summaryAttribute": function () {
            this.onNewSummaryUpdated();
        },
        "grid_item.instance_setting.data.displaySettings.summaryKey": function () {
            this.onNewSummaryUpdated();
        },
        "grid_item.instance_setting.data.displaySettings.gridX": function () {
            this.updateChartValues();
        },
        "grid_item.instance_setting.data.displaySettings.gridX": function () {
            this.updateChartValues();
        },
        "grid_item.instance_setting.data.displaySettings.gridY": function () {
            this.updateChartValues();
        },
        "grid_item.instance_setting.data.displaySettings.axisX": function () {
            this.updateChartValues();
        },
        "grid_item.instance_setting.data.displaySettings.axisY": function () {
            this.updateChartValues();
        },
        "grid_item.instance_setting.data.displaySettings.axisAngle": function () {
            this.updateChartValues();
        },
        "grid_item.instance_setting.data.respondToFiltering": function(){
            this.updateChartValues()
        }
    },
    methods: {
        canvasId() {
            return "chart" + this.grid_item.i;
        },
        verifyInstanceSettings() {

            if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};

            if(this.getUniqueAttrTotalByHeader.length>0) if(this.getUniqueAttrTotalByHeader[0].total<50) this.selectedAttr = this.getUniqueAttrTotalByHeader[0].name;
            // console.log("Databar",this.getUniqueAttrTotalByHeader[0])

            if (!this.grid_item.instance_setting.data.selectedAttr) this.grid_item.instance_setting.data.selectedAttr = this.selectedAttr;
            if (!this.grid_item.instance_setting.data.selectedAttrType) this.grid_item.instance_setting.data.selectedAttrType = this.selectedAttrType;

            if (!this.grid_item.instance_setting.data.displaySettings) this.grid_item.instance_setting.data.displaySettings = {};
            if (!('isFlipped' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.isFlipped = this.isFlipped;
            if (!('gridX' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.gridX = this.gridX;
            if (!('gridY' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.gridY = this.gridY;
            if (!('axisX' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.axisX = this.axisX;
            if (!('axisY' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.axisY = this.axisY;
            if (!('axisAngle' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.axisAngle = this.axisAngle;
            if (!('summaryAttribute' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.summaryAttribute = this.summaryAttribute;
            if (!('summaryKey' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.summaryKey = this.summaryKey;
            if (!('summaryType' in this.grid_item.instance_setting.data.displaySettings)) this.grid_item.instance_setting.data.displaySettings.summaryType = this.summaryType;
            if (!this.grid_item.instance_setting.data.respondToFiltering) this.grid_item.instance_setting.data.respondToFiltering = this.respondToFiltering
            if (!this.grid_item.instance_setting.data.uiSettings) this.grid_item.instance_setting.data.uiSettings = {};

        },
        newDatasetLoadStartedEvent() {
            this.datasetSwitchingLoading = true;
        },
        selectedAttrChanged() {
            if (this.grid_item.instance_setting.data.selectedAttr) {
                let result = this.getAttrHeaderOptions.filter(a => a === this.grid_item.instance_setting.data.selectedAttr);
                if (result.length === 0) {
                    this.grid_item.instance_setting.data.selectedAttr = this.getAttrHeaderOptions[0];
                }
            }

            this.updateChartValues();
        },
        async updateChartValues(){
            if (!chartInstances[this.grid_item.i]) return;
            chartInstances[this.grid_item.i].axisAngle = this.grid_item.instance_setting.data.displaySettings.axisAngle;
            let data = (this.getFilterByData.graphicData && this.grid_item.instance_setting.data.respondToFiltering) ? this.getFilterByData.graphicData : this.getAttrData
            chartInstances[this.grid_item.i].addBarPlot(
                data,
                "ellipseId",
                this.grid_item.instance_setting.data.selectedAttr,
                this.grid_item.instance_setting.data.displaySettings.summaryType,
                this.grid_item.instance_setting.data.displaySettings.summaryKey,
                this.grid_item.instance_setting.data.displaySettings.isFlipped,
                { 
                    xGrid: this.grid_item.instance_setting.data.displaySettings.gridX, 
                    yGrid: this.grid_item.instance_setting.data.displaySettings.gridY,
                    xAxis: this.grid_item.instance_setting.data.displaySettings.axisX, 
                    yAxis: this.grid_item.instance_setting.data.displaySettings.axisY
                }
                );
            await this.onNewColorByUpdated();
            await this.onNewElementsSelected();
            await this.onNewElementsHighlighted();
        },
        globalHighlightColorEvent(highlight_color) {
            if (!chartInstances[this.grid_item.i]) return;
            chartInstances[this.grid_item.i].highlightColor = highlight_color;
        },
        globalSelectionColorEvent(selection_color) {
            if (!chartInstances[this.grid_item.i]) return;
                chartInstances[this.grid_item.i].selectionColor = selection_color;
        },
        globalSignificantDigitsEvent(significant_digits) {
            if (!chartInstances[this.grid_item.i])  return;
                chartInstances[this.grid_item.i].digits = significant_digits;
        },
        async creatingInstanceAndCanvas() {
            //Get Div for new Viewer
            chartCount += 1;
            let canvasId = this.grid_item.i;
            let canvas = this.$refs.chartCanvas;

            let selectionColor = this.getGlobalProperty.global_setting.selection_color;

            let highlightColor = this.getGlobalProperty.global_setting.highlight_color;
            let backgroundColor = this.grid_item.local_setting.foreground_color;
 
            let defaultColor = this.$vuetify.theme.current.colors.darkSlate;

            let customChartSettings = {
                defaultColor: defaultColor,
                backgroundColor: backgroundColor,
                selectionColor: selectionColor,
                highlightColor: highlightColor,
            };

            let response = chartMgrInstance.addPlotInstance('bar', canvas, canvasId, customChartSettings);

            if (response["status"] == "success") {
                chartInstances[this.grid_item.i] = chartMgrInstance.getPlotByName('bar', canvasId);
                this.updateViewerEvents(this.grid_item.i);
            }
        },
        async onNewElementsSelected() {
            if (!chartInstances[this.grid_item.i]) return;
            chartMgrInstance.selectElementsInAllPlots(this.getSelectedElements);
        },
        async onNewElementsHighlighted() {
            if (!chartInstances[this.grid_item.i]) return;
            chartMgrInstance.highlightElementsInAllPlots(this.getHighlightedElements);
        },
        async onNewColorByUpdated() {
            if (!chartInstances[this.grid_item.i]) return;
            if(!this.getColorByData.graphicData) {
                chartInstances[this.grid_item.i].clearAllColorBy();
                return;
            } 
            chartInstances[this.grid_item.i].colorElements(this.getGlobalProperty.global_setting.color_by_attribute, this.getColorByData.graphicData.legendDict);
        },
        async onNewFilterByUpdated() {
           this.updateChartValues()
        },
        async updateWidget() {
            let postData = {
                widgets: [this.grid_item],
            };
            let update_widgets = await this.$auth.$api.post(
                "/api/widget/update_widgets",
                postData
            );
        },
        updateDisplaySettings() {
            //NOT IMPLEMENTED
        },
        updateViewerEvents(viewerId) {
            //Multi Select Refactor Here....
            chartInstances[this.grid_item.i].highlightCheck(true);
            chartInstances[viewerId].registerEvent("element-select", this.elementSelectEvent);
            chartInstances[viewerId].registerEvent("element-highlight", (e) => {this.setHighlightedElements(e);});
        },
        elementHighlightEvent(ellipseIds) {
        if(ellipseIds === ""){
          this.notebookPropsStore.$patch({selectedElements: []})
        }
        else{
          this.notebookPropsStore.$patch({selectedElements: ellipseIds})
        }
        },
        elementSelectEvent(ellipseIds) {
        if(ellipseIds === ""){
          this.notebookPropsStore.$patch({selectedElements: []})
        }
        else{
          this.notebookPropsStore.$patch({selectedElements: ellipseIds})
        }
        },
        onNewSummaryUpdated() {
            if (this.grid_item.instance_setting.data.displaySettings.summaryAttribute) {
                if(this.getAttrHeadersNumerical.includes(this.grid_item.instance_setting.data.displaySettings.summaryAttribute))
                {
                    this.grid_item.instance_setting.data.displaySettings.summaryKey = this.grid_item.instance_setting.data.displaySettings.summaryAttribute;
                    if (this.grid_item.instance_setting.data.displaySettings.summaryAttribute == "None") {
                        this.grid_item.instance_setting.data.displaySettings.summaryKey = undefined;
                        this.grid_item.instance_setting.data.displaySettings.summaryType = "";
                    }
                    else
                    {
                        this.grid_item.instance_setting.data.displaySettings.summaryType = "value";
                    }

                    this.updateChartValues();
                }
            }
        },
    },
};
</script>
  
<style scoped>
.v-input:deep(.v-select__selection) {
    display: flex;
    align-items: center;
}

.v-input:deep(.v-select__selection-text) {
    font-size: 14px !important;
    width: 170px;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px
}
</style>
  