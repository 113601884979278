<template>
  <!-- 3D Widget Properties -->
  <div >
    <v-expansion-panels multiple density="compact" hover>
      <v-expansion-panel elevation="0">
        <v-expansion-panel-title class="dense-expansion-panel">
          <span class="font-weight-bold ma-0 pa-0">
            <v-icon class=" pa-0 ma-0 pr-2" >{{ lexicon.widget.icon }}</v-icon>
          Widget Controls
          </span>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row width="100%">
            <!-- Fullscreen. -->
            <v-col lg="6">
              <v-switch
                hide-details
                v-model="getSelectedWidget.instance_setting.data.uiSettings.showFullscreen"
                label="Fullscreen"
              />
            </v-col>
            <!-- Downloads. -->
            <v-col lg="6">
              <v-switch
                hide-details
                v-model="getSelectedWidget.instance_setting.data.uiSettings.showDownloads"
                label="Downloads"
              />
            </v-col>
          </v-row>
          <v-row width="100%">
            <!-- Layers. -->
            <v-col lg="6">
              <v-switch
                hide-details
                v-model="getSelectedWidget.instance_setting.data.uiSettings.showLayers"
                label="Layers"
              />
            </v-col>
            <!-- Models. -->
            <v-col lg="6">
              <v-switch
                hide-details
                v-model="getSelectedWidget.instance_setting.data.uiSettings.showModels"
                label="Models"
              />
            </v-col>
          </v-row>
          <v-row width="100%">
            <!-- Zoom. -->
            <v-col lg="6">
              <v-switch
                hide-details
                v-model="getSelectedWidget.instance_setting.data.uiSettings.showZoom"
                label="Zoom"
              />
            </v-col>
            <!-- Multi. -->
            <v-col lg="6">
              <v-switch
                hide-details
                v-model="getSelectedWidget.instance_setting.data.uiSettings.showMultiSel"
                label="Multi Sel"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels> 
    
    <v-container style=" 'height:100%;  overflow-y: auto;">
    
      <template v-if="getSelectedWidget.instance_setting.layers">
        <v-divider class="mt-0 mb-4"></v-divider>
        <!-- Layer Selection Headder-->
        <v-row width="100%"><div class="text-caption pt-0"><strong>Layers</strong></div><v-divider/></v-row>

        <!-- Layer Selection Dropdown-->
        <v-row width="100%" class="pt-4 pl-2">
            <v-combobox
            v-model="getSelectedWidget.instance_setting.layers.layerBy"
            :items="getAttrHeadersCategorical"
            :label="'Select Attribute ('+getAttrHeadersCategorical.length +')'"
            @update:modelValue="onSelectLayerBy()"
            ></v-combobox>
        </v-row>
      </template>

      <!-- Lighting Section Headder. -->
      <v-row width="100%"><div class="text-caption pt-4"><strong>Lighting</strong></div><v-divider/></v-row>
      <v-row width="100%" class="pb-2 pl-2 ma-0">
        <!-- Sky. -->
        <v-col lg="6">
          <v-switch
            hide-details
            v-model="getSelectedWidget.instance_setting.data.displaySettings.sky"
            label="Sky"
          />
        </v-col>
        <!-- Shadow. -->
        <v-col lg="6">
          <v-switch
            hide-details
            v-model="getSelectedWidget.instance_setting.data.displaySettings.shadow"
            label="Shadows"
          />
        </v-col>
      </v-row>
      <!-- Sun Position. -->
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <!-- Adimuth. -->
        <v-col lg="6"
          class="pb-0">
          <div class="text-caption pb-0">Azimuth | {{ getSelectedWidget.instance_setting.data.displaySettings.azimuth }}
          </div>
          <v-slider
            v-model="getSelectedWidget.instance_setting.data.displaySettings.azimuth"
            :prepend-icon="icons.azimuth"
            min="0"
            max="360"
            step="0.1"
            thumb-label
            hide-details
            color="primary"
          ></v-slider>
        </v-col>
        <!-- Altitude. -->
        <v-col lg="6"
          class="pb-0">
          <div class="text-caption">Altitude | {{ getSelectedWidget.instance_setting.data.displaySettings.altitude }}</div>
          <v-slider
            v-model="getSelectedWidget.instance_setting.data.displaySettings.altitude"
            :prepend-icon="icons.altitude"
            min="0"
            max="90"
            step="0.1"
            thumb-label
            hide-details
            color="primary"
          ></v-slider>
        </v-col>
      </v-row>
    
      <v-row width="100%"><div class="text-caption pt-4"><strong>Ground</strong></div><v-divider/></v-row>
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <!-- Show Ground. -->
        <v-col lg="6">
          <v-switch
            v-model="getSelectedWidget.instance_setting.data.displaySettings.ground"
            label="Ground"
            class="pt-2"
          />
        </v-col>
        <!-- Ground Height. -->
        <v-col lg="6">
          <v-text-field 
          class="pt-2"
            v-model="getSelectedWidget.instance_setting.data.displaySettings.groundheight" 
            variant="outlined" 
            density="compact" 
            type="number" 
            hide-details
            :prepend-inner-icon="icons.ground"
            label="Ground Height"
          />
        </v-col>
      </v-row>
      <!-- Ground Color. -->
      <v-row width="90%" class="pl-2 ma-0 pt-2">
        <v-expansion-panels>
          <v-expansion-panel elevation="0">
            <!-- Ground Color header. -->
            <v-expansion-panel-title class="dense-expansion-panel">
              <span style="margin-left: -20px">Ground Color</span>
              <template v-slot:actions>
                <v-icon
                :color="getSelectedWidget.instance_setting.data.displaySettings.groundcolor"
                large
                >
                {{ icons.circle }}
                </v-icon>
              </template>
            </v-expansion-panel-title>
            <!-- Ground Color Picker. -->
            <v-expansion-panel-text class="dense-expansion-panel">
              <v-color-picker
                v-model="getSelectedWidget.instance_setting.data.displaySettings.groundcolor"
                dot-size="4"
                mode="hexa"
                canvas-height="80"
                width="230"
                swatches-max-height="10"
                style="margin-left: -12px"
              ></v-color-picker>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>

      <v-row width="100%"><div class="text-caption pt-2"><strong>Materials</strong></div><v-divider/></v-row>
      <!-- Show Edges. -->
      <v-row width="100%" class="pa-0 pl-2 ma-0">
          <v-switch
            v-model="getSelectedWidget.instance_setting.data.displaySettings.edges"
            label="Edges"
          />
      </v-row>
      <v-row width="100%"><div class="text-caption pt-2"><strong>Viewer Settings</strong></div><v-divider/></v-row>
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <!-- Label Size -->
        <v-col >
          <v-text-field 
            class="pa-0 pt-4"
            v-model="getSelectedWidget.instance_setting.data.displaySettings.labelSize" 
            variant="outlined" 
            density="compact" 
            type="number" 
            hide-details
            :prepend-inner-icon="icons.labelSize"
            label="Label Size"
          />
        </v-col>
      </v-row>
    
    <!-- Tooltip. -->
    <v-row width="100%"><div class="text-caption pt-4"><strong>Tooltip Settings</strong></div><v-divider/></v-row>
    <v-row width="100%" class="pa-0 pl-2 ma-0">
        <ChipGroup
          :options="getAttrHeaderNames" 
          :selections="getSelectedWidget.instance_setting.data.filteredHeaders" 
          @table-changed="updatedSelection" 
        />
    </v-row>
    </v-container>
  </div>
</template>

<script>
import bus from "../../plugins/bus.js";
import ChipGroup from "../ui/ChipGroup.vue";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { evaluate } from "@ttcorestudio/data-processor";
import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";

export default {
  props:{
    getSelectedWidget:{}
  },
  components:{
    ChipGroup,
  },
  setup() {
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrData,
      getAttrHeadersCategorical,
      getAttrHeaderNames
    } = storeToRefs(dataGraphicsStore)
    return {getAttrData, getAttrHeaderNames, getAttrHeadersCategorical, lexicon, icons}
  },
  data:()=>({
    selection: [],
    data_headers: [],
    loaded: false,
  }),
  computed:{
  },
  methods:{
    updatedSelection(selected){
      this.getSelectedWidget.instance_setting.data.filteredHeaders = selected;
    },
    onSelectLayerBy() {
      // set the layers object get unique values and add isVisible:, isLocked, opacity values:
      let key = this.getSelectedWidget.instance_setting.layers.layerBy;

      let elemVals = [];
      this.getAttrData.forEach((attr) => {
        elemVals.push(attr[key]);
      });

      let tempVal = evaluate.getUniqueVals(elemVals);

      this.getSelectedWidget.instance_setting.layers.layers = tempVal.map(
        (item) => {
        const layer = {};
        layer.name = item;
        layer.isVisible = true;
        layer.isLocked = false;
        layer.opacity = 1;
        layer.elemIds = this.getAttrData.reduce(function (acc, att) {
          if (att.hasOwnProperty(key) && att[key] === item)
          acc.push(att.ellipseId);
          return acc;
        }, []);
        return layer;
        }
      );
    },
  }
}
</script>

<style scoped>

.dense-expansion-panel {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 1 !important;
  padding-right: 1 !important;
}

</style>