<template>
  <div class="pageHeight">
    <v-container >

    <v-row>
      <v-col  cols="12" sm="2"></v-col>
      <v-col  cols="12" sm="8">
        <v-card flat>
          <span class="header" >Download Plugins</span>
          <p class="ol- pa-3 mb-0 description">
            Upload content directly to Ellipse from multiple desktop applications. Each plugin provides application specific options to filter and organize data and coordinate it with 2D and 3D content.
          </p>
        <v-divider></v-divider>
        </v-card >
          <v-card class="px-5 pb-4" flat>
              <div>
                <v-table
                  density="compact"
                >
                  <thead>
                    <tr >
                      <th v-for="item in applicationHeaders" :key="item.key">
                        <td style="font-size:12px;">{{item.text}}</td>
                      </th>
                    </tr>
                  </thead>
                  <tbody style="font-size: 14px">
                    <tr v-for="item in applications" :key="item.key"  :title="item.description">
                      <td>
                        <div>
                          <v-img
                            :src="item.image"
                            width="25"
                            height="25"
                          ></v-img>
                        </div>
                      </td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.platform }}</td>
                      <td>{{ item.pluginVersion }}</td>
                      <td>
                        <v-btn
                        icon
                        size="small"
                        variant="text"
                          :href="item.link"
                          target="_blank"
                        >
                          <v-icon color="primary" title="Download Installer"> {{ item.linkImage }} </v-icon></v-btn
                        >
                      </td>
                      <td>
                        <v-btn
                        icon
                        size="small"
                        variant="text"
                          :href="item.zip"
                        >
                          <v-icon color="secondary" title="Download Plugin Files Zip"> {{ item.zipImage }} </v-icon></v-btn
                        >
                      </td>
                      <td >
                        <v-btn
                        icon
                        size="small"
                        variant="text"
                          :href="item.sample"
                        >
                          <v-icon color="primary" title="Download Sample"> {{ item.sampleImage }} </v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </div>
          <v-divider></v-divider>
          </v-card>
              <v-btn
              block
        color="secondary"
      href="https://core-studio.gitbook.io/ellipse/getting-started/download-plugins"
      target="_blank"
      link
    >
    <v-icon size="large" class="pr-5">{{ icons.help }}</v-icon> Click here to learn more about installing plugins
    </v-btn>
      </v-col>
      <v-col  cols="12" sm="2"></v-col>
    </v-row>
  </v-container>

  </div>
</template>

<script>
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  setup() {
    return {icons}
  },
  data() {
    return {
      applicationHeaders: [
        {
          text: " ",
          align: "start",
          sortable: false,
          value: "image",
          width: "5%",
          align: "left",
        },
        {
          text: "Name",
          value: "name",
          width: "20%",
          align: "left",
        },
        {
          text: "Platform",
          value: "platform",
          width: "20%",
          align: "left",
        },
        {
          text: "Plugin Version",
          value: "PluginVersion",
          width: "25%",
          align: "left",
        },
        {
          text: "Installer Download",
          value: "link",
          width: "10%",
          align: "center",
        },
        {
          text: "Alt. Zip Download",
          value: "zip",
          width: "10%",
          align: "center",
        },
        {
          text: "Sample",
          value: "sample",
          width: "10%",
          align: "center",
        },
      ],
      applications: [
        {
          key: "0",
          image: require("../assets/imgs/RevitIcon.png"),
          name: "Revit Uploader",
          platform: "Revit 2021",
          description: "Autodesk Revit 2021 Addin",
          pluginVersion: "0.4.3",
          link: "https://core-ellipse-builds.s3.amazonaws.com/CORE.Ellipse.Revit2021.exe",
          zip: "https://core-ellipse-builds.s3.amazonaws.com/Ellipse+Revit+2021+Uploader.zip",
          sample:
            "https://core-ellipse-builds.s3.amazonaws.com/Ellipse_Sample-Revit_2021.zip",
          linkImage: icons.download ,
          zipImage: icons.zipFile ,
          sampleImage: icons.sampleFile ,
        },
        {
          key: "1",
          image: require("../assets/imgs/RevitIcon.png"),
          name: "Revit Uploader",
          platform: "Revit 2022",
          description: "Autodesk Revit 2022 Addin",
          pluginVersion: "0.4.3",
          link: "https://core-ellipse-builds.s3.amazonaws.com/CORE.Ellipse.Revit2022.exe",
          zip: "https://core-ellipse-builds.s3.amazonaws.com/Ellipse+Revit+2022+Uploader.zip",
          sample:
            "https://core-ellipse-builds.s3.amazonaws.com/Ellipse_Sample-Revit_2022.zip",
          linkImage: icons.download ,
          zipImage: icons.zipFile ,
          sampleImage: icons.sampleFile ,
        },
        {
          key: "2",
          image: require("../assets/imgs/RevitIcon.png"),
          name: "Revit Uploader",
          platform: "Revit 2023",
          description: "Autodesk Revit 2023 Addin",
          pluginVersion: "0.4.3",
          link: "https://core-ellipse-builds.s3.amazonaws.com/CORE.Ellipse.Revit2023.exe",
          zip: "https://core-ellipse-builds.s3.amazonaws.com/Ellipse+Revit+2023+Uploader.zip",
          sample:
            "https://core-ellipse-builds.s3.amazonaws.com/Ellipse_Sample-Revit_2023.zip",
          linkImage: icons.download ,
          zipImage: icons.zipFile ,
          sampleImage: icons.sampleFile ,
        },
        {
          key: "3",
          image: require("../assets/imgs/RevitIcon.png"),
          name: "Revit Uploader",
          platform: "Revit 2024",
          description: "Autodesk Revit 2024 Addin",
          pluginVersion: "0.4.3",
          link: "https://core-ellipse-builds.s3.amazonaws.com/CORE.Ellipse.Revit2024.exe",
          zip: "https://core-ellipse-builds.s3.amazonaws.com/Ellipse+Revit+2024+Uploader.zip",
          sample:
            "https://core-ellipse-builds.s3.amazonaws.com/Ellipse_Sample-Revit_2024.zip",
          linkImage: icons.download ,
          zipImage: icons.zipFile ,
          sampleImage: icons.sampleFile ,
        },
        {
          key: "4",
          image: require("../assets/imgs/TTToolbox.png"),
          name: "TT Toolbox",
          platform: "Grasshopper 3d",
          description: "TT Toolbox 2 Plugin for Grasshopper 3d enables 3dm, json, and svg file exports that are compatible with Ellipse.",
          pluginVersion: "",
          link: "https://www.food4rhino.com/en/app/tt-toolbox",
          zip: "",
          sample:
            "https://core-ellipse-builds.s3.amazonaws.com/Ellipse_Sample-Grasshopper.zip",
          linkImage: icons.linkFile ,
          zipImage: "",
          sampleImage: icons.sampleFile ,
        },
      ],
    };
  },
  created() {},
};
</script>

<style scoped>
</style>
