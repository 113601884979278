<template>
  <div class="sidePanelMargin">
    <!-- Tab Headder. -->
    <div class="sidePanelHeader">
      <v-col class="pa-0">
        <!-- Tab Title. -->
        <v-row>
          <h2 class="text-capitalize sidePanelInner header">
            {{lexicon.asset.plural}} <HelpButton :lexiconItem="lexicon.dataset"/>
          </h2>
        </v-row>
        <div class="divider">
          <v-divider></v-divider>
        </div>
      <HelpAlert :message="messages.uploadAlert" class="mb-2"/>
        <BulkUploadDialog />

      </v-col>
    </div>

          <!-- Data, Drawings, Assets, and Files Tabs. -->
          <v-tabs
            fixed-tabs
            next-icon="false"
            prev-icon="false"
            background-color="background"
            v-model="tab"
          >

            <!-- Data Tab. -->
            <v-tab value="data" href="#data" class="mx-1">
              <span class="text-primary text-capitalize">Data</span>
            </v-tab>

            <!-- 3D Models Tab. -->
            <v-tab value="3D" href="#3D" class="mx-1">
              <span class="text-primary">3D</span>
            </v-tab>

            <!-- 2D Drawings Tab. -->
            <v-tab value="2D" href="#2D" class="mx-1">
              <span class="text-primary">2D</span>
            </v-tab>

            <!-- GIS Tab. -->
            <v-tab value="GIS" href="#GIS" class="mx-1">
              <span class="text-primary">GIS</span>
            </v-tab>

            <!-- Image Tab. -->
            <v-tab value="IMG" href="#IMG" class="mx-1">
              <span class="text-primary">IMG</span>
            </v-tab>

            <!-- Files Tab. -->
            <v-tab value="files" href="#files" class="mx-1">
              <span class="text-primary text-capitalize">Files</span>
            </v-tab>
          </v-tabs>

          <v-window v-model="tab">
            <v-window-item value="data">
              <AssetsManage :assetType="'data'" :icon=lexicon.dataset.icon />
            </v-window-item >
            <v-window-item value="3D" >
              <AssetsManage :assetType="'3D'" :icon=lexicon.model.icon />
            </v-window-item >
            <v-window-item value="2D">
              <AssetsManage :assetType="'2D'" :icon=lexicon.drawing.icon />
            </v-window-item >
            <v-window-item value="GIS">
              <AssetsManage :assetType="'map'" :icon=lexicon.map.icon />
            </v-window-item >
            <v-window-item value="IMG">
              <AssetsManage :assetType="'image'" :icon=lexicon.image.icon />
            </v-window-item >
            <v-window-item value="files">
              <AllFiles />
            </v-window-item >
          </v-window>
          
  </div>
</template>

<script>
import AssetsManage from "./SidePanelAssetsManage.vue";
import AllFiles from "./SidePanelAssetsAllFiles.vue";
import BulkUploadDialog from "./SidePanelAssetsBulkUploadDialog.vue";
import HelpButton from "../components/ui/HelpButton.vue";
import HelpAlert from "../components/ui/HelpAlert.vue";

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";
import * as icons from "@/utilities/EllipseIcons.js";

import {storeToRefs} from 'pinia'
import {useAssetsStore} from "@/store/AssetsStore.js";

export default {
  components: { AssetsManage, AllFiles, BulkUploadDialog, HelpButton, HelpAlert},
  setup() {
    const assetsStore = useAssetsStore()
    const {
      getAllAssetsInNotebook
    } = storeToRefs(assetsStore)
    return {
      assetsStore, 
      getAllAssetsInNotebook, 
      lexicon, 
      messages, 
      icons
    }
  },
  data() {
    return {
      tab: "data",
      panel: [0],
    };
  },
  computed: {
  },
  created() {
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.v-tab {
  min-width: 10px !important;
  max-width: 70px !important;
  padding: 0 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.v-application .text-caption {
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 0.8rem !important;
  letter-spacing: 0.02em !important;
  font-family: "Roboto", sans-serif !important;
}
.dense-expansion-panel {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.v-expansion-panel-text :deep(.v-expansion-panel-text__wrapper) {
  padding: 0 !important;
}
.v-expansion-panel-title {
  padding: 16px 5px !important;
}

.dense-expansion-panel {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 1 !important;
  padding-right: 1 !important;
}
</style>
