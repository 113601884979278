<template>
  <v-dialog
    :persistent="uploadedFiles.length > 0"
    v-model="uploadAssetDialog"
    width="100%"
    height="110%"
    max-width="1000px"
  >
    <!-- Upload Assets Button in side panel. -->
    <template v-slot:activator="{ props}" v-model="dialog">
      <v-btn
        v-bind="props"
        style="width: 100%"
        variant="outlined"
        rounded
        color="primary"
      >
        <v-icon class="pr-1" size="small">{{ icons.upload}}</v-icon>
        Upload Assets
      </v-btn>
      
    </template>

    <!-- Drag and Drop Dialoge. -->
    <v-card
      @drop.prevent="onDrop($event)"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      :class="{ 'grey-lighten-2': dragover }"
    >
      <!-- Upload Assets Dialoge title. -->
      <v-card-title
        v-if="uploadedFiles.length === 0"
        class="bg-primary d-flex justify-center text-white"
      >
        <v-icon color="white" class="mr-4" size="small" me-auto>{{ icons.cloudUpload }}</v-icon>
        <span me-auto>Upload Assets</span>
        <v-spacer></v-spacer>
        <HelpButton :lexiconItem="lexicon.upload" :iconColor="'white'" class="pr-2"/>
        <v-icon @click="clearAssets" > {{icons.cancel}} </v-icon>
      </v-card-title>

      <!-- Review Uploads Dialoge title. -->
      <v-card-title v-else class="bg-primary text-white">
        <v-icon color="white" class="mr-4" size="small">{{ icons.cloudUpload }}</v-icon>
        Review Uploads
      </v-card-title>

      <!-- Dialoge Body. -->
      <v-container fluid>

        <!-- Drag and Drop Area. -->
        <v-row
          v-if="uploadedFiles.length === 0"
          style="border: dashed 2px rgb(var(--v-theme-darkGrey))"
          class="d-flex flex-column ma-1 pa-3"
          density="compact"
          align="center"
          justify="center"
        >
          <v-icon
            color="primary"
            :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
            size="60"
          >
            {{ icons.cloudUpload }}
          </v-icon>
          <div class="text-center">
            <span class="text-body-1 font-weight-black"
              >Drag and drop files here</span
            >
            <br />
            (json, 3dm, svg, geojson, png...)
          </div>
          <span class="py-2">or</span>
          <v-btn @click="onFileUpload" size="large" class="bg-primary text-white"
            >Browse Files</v-btn
          >
          <input
            class="d-none"
            ref="uploader"
            type="file"
            multiple="multiple"
            @change="uploadFile($event)"
          />
        </v-row>

        <!-- Upload Review Area.-->
        <div v-else>

          <!-- File Type Specification Alert. -->
          <v-alert
            class="ma-1 py-2"
            close-text="Close Alert"
            color="secondary"
            closable
          >
            <div>
              <span>Select how each file should be used in the notebook<br />
                <ul>
                  <li>
                    <small>
                      <a :href="lexicon.data.documentation" target="_blank" style="font-weight: bold; color: white">{{lexicon.data.plural}}</a> <i>(.json, .csv)</i>
                      can be visualized with multiple Widgets.
                    </small>
                  </li>
                  <li>
                    <small>
                      <a :href="lexicon.model.documentation" target="_blank" style="font-weight: bold; color: white">{{lexicon.model.plural}}</a> <i>(.3dm)</i>
                      are viewable in the 3D Widget
                    </small>
                  </li>
                  <li>
                    <small>
                      <a :href="lexicon.drawing.documentation" target="_blank" style="font-weight: bold; color: white">{{lexicon.drawing.plural}}</a> <i>(.svg)</i>
                      are viewable in the 2D Widget.
                    </small>
                  </li>
                  <li>
                    <small>
                      <a :href="lexicon.map.documentation" target="_blank" style="font-weight: bold; color: white">{{lexicon.map.plural}}</a> <i>(.geojson)</i>
                      are viewable in the Map Widget.
                    </small> 
                  </li>
                  <li>
                    <small>
                      <a :href="lexicon.image.documentation" target="_blank" style="font-weight: bold; color: white">{{lexicon.image.plural}}</a> <i>(.jpg, .jpeg, .png, .bmp, .gif)</i>
                      are viewable in the Image Grid Widget.
                    </small> 
                  </li>
                  <li>
                    <small>All other supported <a :href="lexicon.file.documentation" target="_blank" style="font-weight: bold; color: white">{{lexicon.file.plural}}</a>
                      formats can be added as Attachments to a Notebook.
                    </small>
                  </li>
                </ul>
              </span>
            </div>
          </v-alert>
           <v-table
            v-if="displayTable"
            class="pt-2"
            density="compact"
            :key="groupUpdateKey"
            :disable-pagination="true"
            :hide-default-footer="true"
            max-height="300px"
          >
          <!-- Table Headers. -->
            <thead>
              <tr>
                <th v-for="header in uploadFilesHeaders" :key="header.text">
                  {{header.text}}
                </th>
              </tr>
            </thead>
            <!-- Table Body -->
            <tbody>
              <!-- Each Row. -->
              <tr v-for="(file, index) in uploadedFiles" :key="index">
                <!-- Cancel Column. -->
                <td style="width: 4%">
                  <template v-if="file.uploadPercentage === 100">
                    <v-icon color="primary" @click="removeFile(file.name)">{{ icons.check }}</v-icon>
                  </template>
                  <template v-else>
                    <v-icon v-if="isNaN(file.uploadPercentage)" color="danger">{{ icons.error }}</v-icon>
                    <v-icon  color="darkGrey" v-else @click="removeFile(file.name)">{{ icons.cencel }}</v-icon>
                  </template>
                </td>
                <!-- File Name Column. -->
                <td class="file-name-cell text-truncate">
                  <v-icon size="x-small" color="darkGrey" :key="fileIconKey+index">{{ file.icon }}</v-icon>
                  <small>
                    &nbsp; {{ file.name }}
                    <v-tooltip activator="parent" location="top">{{file.name}}</v-tooltip>
                  </small>
                  <div>
      
                    <v-progress-linear
                    class="mt-0"
                      v-if="showFileUploadProgres[`${file.name}`]"
                      height="8"
                      rounded
                      v-model="file.uploadPercentage"
                      :color="file.isValidFile ? 'secondary' : 'danger'"
                    >
                      <small
                        style="
                          color: white;
                          font-size: xx-small;
                          font-weight: 500;
                        "
                      >
                        {{ Math.ceil(file.uploadPercentage) }}%</small
                      >
                    </v-progress-linear>
                  </div>
                </td>                
                <!-- File Type Column. -->
                <td >
                  {{ file.extension }}
                </td>
                <!-- 3D, 2D, GIS, Data, File Columns. -->
                <td >
                    <v-radio-group :disabled="is3dDisabled(file.extension)" 
                    hide-details color="primary" 
                    :model-value="file.is3D" 
                    @update:modelValue="updateExtension(file, 'is3D')" 
                    :key="radioKey">
                      <v-radio density="compact" value="true" >                        
                      </v-radio>
                    </v-radio-group>
                </td>
                <td >
                    <v-radio-group :disabled="is2dDisabled(file.extension)" 
                    :model-value="file.is2D" 
                    @update:modelValue="updateExtension(file, 'is2D')" 
                    :key="radioKey">
                      <v-radio density="compact" value="true" >
                      </v-radio>
                    </v-radio-group>
                </td>
                <td >
                    <v-radio-group :disabled="isGisDisabled(file.extension)" 
                    :model-value="file.isGIS" 
                    @update:modelValue="updateExtension(file, 'isGIS')" 
                    :key="radioKey" >
                      <v-radio density="compact" value="true" >
                      </v-radio>
                    </v-radio-group>
                </td>
                <td >
                    <v-radio-group :disabled="isDataDisabled(file.extension)" 
                    :model-value="file.isData" 
                    @update:modelValue="updateExtension(file, 'isData')" >
                      <v-radio density="compact" :dark="isDataDisabled(file.extension)" value="true" :key="radioKey" >
                      </v-radio>
                    </v-radio-group>
                </td>
                <td > 
                    <v-radio-group @update:modelValue="updateExtension(file, 'isDataImg')" :model-value="file.isDataImg" >
                      <v-radio density="compact" :dark="isDatasetFilesDisabled(file.extension)" value="true" :key="radioKey" >
                      </v-radio>
                    </v-radio-group>
                </td>
                <td >
                    <v-radio-group @update:modelValue="updateExtension(file, 'isAsset')" :model-value="file.isAsset" >
                      <v-radio
                      density="compact"
                        value="true"
                        :key="radioKey"
                      ></v-radio
                    ></v-radio-group>
                </td>
              </tr>
            </tbody>
          </v-table>
          <div v-else class="pt-5 d-flex align-center justify-center">
              <ProgressCircular>Loading Files</ProgressCircular>
          </div>

          <!-- Bottom Buttons. -->
          <div class="d-flex pt-5 justify-end">
            <template v-if="allAssetsUploadDone">
              <v-btn
              color="primary"
              @click="clearAssets"
              >Done</v-btn
            >
            </template>
            <template v-else>
              <v-btn
              class="mr-2"
              :disabled="cancelUploadDisabled"
              @click="cancelUpload"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              :loading="uploadAssetLoading"
              @click="startUpload"
              >Upload</v-btn
            >
            </template>
          </div>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import PillButton from "@/components/ui/PillButton.vue";
import * as utils from "@/utilities";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";
import {useEllipseStore } from "@/store/EllipseStore.js";
import ProgressCircular from "../components/ui/ProgressCircular.vue"
import * as uploadProcessing from "@/utilities/uploadProcessing.js";
import HelpButton from "../components/ui/HelpButton.vue";

export default {
  components: { PillButton, ProgressCircular, HelpButton },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const assetsStore = useAssetsStore()
    const ellipseStore = useEllipseStore()
    const {
      getSelectedNavigationTab,
      getSelectedPage,
      getPagesStructuredData
    } = storeToRefs(notebookPropsStore)
    const {
      getNotebook
    } = storeToRefs(ellipseStore)
    const {
      getAllAssetsInNotebook,
      getSelectedPageDatasets,
      getSelectedPageDataset,
    } = storeToRefs(assetsStore)
    const { 
      setAllAssetsInNotebook,
      setSelectedPageDataset
    } = assetsStore
    return {
      notebookPropsStore,
      getSelectedNavigationTab,
      getAllAssetsInNotebook,
      getSelectedPageDatasets,
      getSelectedPageDataset,
      getSelectedPage,
      getNotebook,
      getPagesStructuredData,
      setAllAssetsInNotebook,
      setSelectedPageDataset,
      lexicon,
      icons
    }
  },
  data() {
    return {
      options () {
        return {
          offset: -60,
          duration: 300,
        }
      },
      dragover: false,
      uploadedFiles: [],
      uploadAssetDialog: false,
      uploadFilesHeaders: [
        { text: "", value: "", align: "center" },
        { text: "File Name", value: "name", align: "center" },
        { text: "Type", value: "extension", align: "center" },
        { text: "3D", value: "is3D", align: "center" },
        { text: "2D", value: "is2D", align: "center" },
        { text: "GIS", value: "isGIS", align: "center" },
        { text: "Data", value: "isData", align: "center" },
        { text: "Img", value: "isDataImg", align: "center" },
        { text: "File", value: "isAsset", align: "center"},
      ],
      is3D: "false",
      is2D: "true",
      isData: "false",
      isAsset: "true",
      isDataImg: "false",
      imageExtensions: ["gif", "jpeg", "png", "bmp"],
      modelExtensions: ["3dm"],
      mapExtensions: ["geojson"],
      drawingExtensions: ["svg"],
      dataExtensions: ["json"],
      cancelUploadDisabled: false,
      uploadAssetLoading: false,
      showFileUploadProgres: {},
      groupUpdateKey: 0,
      allAssetsUploadDone:false,
      radioKey:1,
      displayTable:false,
      jsonFiles:[],
      otherFiles:[],
      fileIconKey: 0,
      dialog: false,
    };
  },

  created() {
    if (
      this.getAllAssetsInNotebook.datasets.length === 0 &&
      this.getAllAssetsInNotebook.drawings.length === 0 &&
      this.getAllAssetsInNotebook.models.length === 0 &&
      this.getSelectedNavigationTab === "globalSources"
    ) {
      this.uploadAssetDialog = true;
    }
  },
  computed: {
  },
  methods: {
    is3dDisabled(extension) {
      let avoid3dExtensions = ["gif", "jpeg", "png", "svg", "json","geojson"];
      let isExtensionPresent = avoid3dExtensions.includes(
        extension.toLowerCase()
      );
      if (isExtensionPresent) {
        return true;
      } else {
        return false;
      }
    },
    is2dDisabled(extension) {
      let avoid2dExtensions = ["gif", "jpeg", "png", "3dm", "json","geojson"];
      let isExtensionPresent = avoid2dExtensions.includes(
        extension.toLowerCase()
      );
      if (isExtensionPresent) {
        return true;
      } else {
        return false;
      }
    },
    isGisDisabled(extension){
      let avoidGisExtensions = ["gif", "jpeg", "png", "3dm", "svg", "json"];
      let isExtensionPresent = avoidGisExtensions.includes(
        extension.toLowerCase()
      );
      if (isExtensionPresent) {
        return true;
      } else {
        return false;
      }
    },
    isDataDisabled(extension) {
      let avoidDataExtensions = ["gif", "jpeg", "png", "3dm", "svg", "geojson"];
      let isExtensionPresent = avoidDataExtensions.includes(
        extension.toLowerCase()
      );
      if (isExtensionPresent) {
        return true;
      } else {
        return false;
      }
    },
    isDatasetFilesDisabled(extension) {
      let avoidDataExtensions = ["gif", "jpeg", "png", "3dm", "svg"];
      let isExtensionPresent = avoidDataExtensions.includes(
        extension.toLowerCase()
      );
      if (isExtensionPresent) {
        return true;
      } else {
        return false;
      }
    },
    setFileIcons() {
      for (let i = 0; i < this.uploadedFiles.length; i++) {
        this.uploadedFiles[i].icon = utils.fileIcon(this.uploadedFiles[i].name);
      }
      this.fileIconKey += 1; //force rerender file icons
    },
    onFileUpload() {
      this.$refs.uploader.click();
    },
    async uploadFile(e) {
      await this.getFileInfo(e.target.files);
    },
    async onDrop(e) {
      this.dragover = false;
      await this.getFileInfo(e.dataTransfer.files);
    },
    /**
     * Determins the file type based on review of extensions and file content.
     * @param {File} files - Files droped into the upload dialog
     */
    async getFileInfo(files){
      let file_list = Array.from(files);
            
      let csvs = [];
      for (const f of file_list) {
        if(f.name.split(".").pop().toLowerCase() === 'csv'){
          let file = await utils.csvToJson(f);
          csvs.push(file);
        }
      }

      file_list = file_list.concat(csvs);
      file_list.forEach((element) => this.uploadedFiles.push(element));

      this.uploadedFiles.forEach((file) => {

        let extension = file.name.split(".").pop().toLowerCase();

        if (this.modelExtensions.includes(extension)) {
          file.uploadType = "model";
        } else if (this.drawingExtensions.includes(extension)) {
          file.uploadType = "drawing";
        } else if (this.mapExtensions.includes(extension)) {
          file.uploadType = "map";
        } else if (this.dataExtensions.includes(extension)) {
          file.uploadType = "data";
        } else {
          file.uploadType = "file";
        }
        file["uploadPercentage"] = 0;
      });

      this.uploadedFiles.forEach((e) => {
        let extension = e.name.split(".").pop().toLowerCase();
        e = this.assignExtensionFlags(e, extension);
      });

      //Review Data Files and maps Images to see if they are data images or assets
      this.sortFileTypes(this.uploadedFiles);
      await this.processDatasetAndDatasetFiles();
      this.setFileIcons();
    },
    assignExtensionFlags(file, extension) {
      file["extension"] = extension;
      file["is3D"] = this.modelExtensions.includes(extension) ? "true" : "false";
      file["is2D"] = this.drawingExtensions.includes(extension) ? "true" : "false";
      file["isGIS"] = this.mapExtensions.includes(extension) ? "true" : "false";
      file["isData"] = this.dataExtensions.includes(extension) ? "true" : "false";
      file["isAsset"] = this.assetCheck(extension) ? "true" : "false";
      file["uploadPercentage"] = 0;
      return file;
    },
    sortFileTypes(files){
      // Differentiate dataset files and other files
      for (const file of files) {
        if (file.extension === "json") {
          this.jsonFiles.push(file);
        } else {
          this.otherFiles.push(file);
        }
      }
    },
    /**
     * Identifes if images are data images or assets.
     * Triggered when files are first dropped into the upload dialog.
     * TODO Could also work for models and drawings in future. 
     * @param {*} files - the files draged into the upload dialog
     */
    async processDatasetAndDatasetFiles(){    

      // Function to update groups
      const updateGroups = (file, newGroups) => {
        if (file.groups) {
          // If groups exist, extend it with unique values
          newGroups.forEach(group => {
            if (!file.groups.includes(group)) {
              file.groups.push(group);
            }
          });
        } else {
          // If groups do not exist, use the new groups array
          file.groups = newGroups;
        }
      };
      /**
       * Merges two objects, combining array values uniquely and overwriting other values.
       * TODO - Move this to a utility function in dataProcessing.js
       * @param {Object} obj1 - The first object to merge.
       * @param {Object} obj2 - The second object to merge.
       * @returns {Object} - The merged object with unique array values.
       */
      const mergeArrays = (obj1, obj2) => {
        // Create a shallow copy of obj1 to avoid mutating the original object
        const result = { ...obj1 };

        // Iterate over each key in obj2
        for (const key in obj2) {
          // Check if the key exists directly on obj2 (not inherited)
          if (obj2.hasOwnProperty(key)) {
            // Check if the value associated with the key is an array
            if (Array.isArray(obj2[key])) {
              // If the key exists in result and its value is an array, merge unique values
              // Otherwise, assign the array from obj2 to the key in result
              result[key] = result[key] 
                ? [...new Set([...result[key], ...obj2[key]])] 
                : obj2[key];
            } else {
              // If the value is not an array, directly assign the value from obj2 to result
              result[key] = obj2[key];
            }
          }
        }

        // Return the merged result object
        return result;
      };

      //iterate through json files and process them
      for (const jsonFile of this.jsonFiles) {
        //Read the JSON file
        let jsonText = await utils.readJsonFile(jsonFile);

        //Only Projecess valid JSON files
        if (this.isJsonString(jsonText)) {
          //Parse the JSON filen
          let jsonData = JSON.parse(jsonText);

          //Array of headers that include attachments
          //& group names to used when attachment headers get flattened into single header
          //& extracted file names and thier assosiated groups
          let validFileHeaders = uploadProcessing.findValidHeaderKeys(jsonData, uploadProcessing.validFileHeaders);
          let fileGroups = {};
          let fileAttachmentGroups = {};

          let validImageHeaders = uploadProcessing.findValidHeaderKeys(jsonData, uploadProcessing.validImageHeaders);
          let imageGroups = {};
          let imageAttachmentGroups = {};

          //Produce Groups for each header
          if (validFileHeaders.length === 0 && validImageHeaders.length === 0){
            //no valid headers found
            //console.log('No valid attachment headers found in JSON file', jsonFile.name);
          } else {
            // Process valid file headers
            fileGroups = this.processHeadersAndGroups(jsonData, uploadProcessing.validFileHeaders);

            // Process valid image headers
            imageGroups = this.processHeadersAndGroups(jsonData, uploadProcessing.validImageHeaders);
          }

          // Process each record in the JSON file to find attachments names and groups
          for (const record of jsonData) {
            // Process each record in the JSON file
            fileAttachmentGroups = mergeArrays(fileAttachmentGroups, this.processRecordAttachments(record, fileGroups));
            imageAttachmentGroups = mergeArrays(imageAttachmentGroups, this.processRecordAttachments(record, imageGroups));
          }
          
          // Mark uploaded files as an attachment
          // And record their groups
          for (let otherFile of this.otherFiles) {

            if(fileAttachmentGroups.hasOwnProperty(otherFile.name)){
              updateGroups(otherFile, fileAttachmentGroups[otherFile.name]);
            } 

            if (imageAttachmentGroups.hasOwnProperty(otherFile.name)) {
              otherFile["isDataImg"] = "true";
              otherFile["isAsset"] = "false";
              updateGroups(otherFile, imageAttachmentGroups[otherFile.name]);
            } 
          }

        }
      }

      this.displayTable = true;
    },
    processHeadersAndGroups(jsonData, validHeaders){
      const headerGroups = {};
      const validHeadersList = uploadProcessing.findValidHeaderKeys(jsonData, validHeaders);
      let groupNumber = 1;

      validHeadersList.forEach(header => {
        if (header.includes('-')) {
          let groupName = header.split('-').slice(1).join('-');
          headerGroups[header] = groupName === '' ? `group-${groupNumber++}` : groupName;
        } else {
          headerGroups[header] = `group-${groupNumber++}`;
        }
      });

      return headerGroups;
    },
    /**
     * Process the headers of a record to find attachments and their groups
     * @param {Object} record - The record to process
     * @param {Object} attachmentGroups - The groups to assign to each attachment eg header: group name
     * returns {Object} dataAttachments - {attachmentName: [group1, group2, ...] }
     */
    processRecordAttachments(record, attachmentGroups) {
      // attachmentGroups = {header: group name}

      //object for each file name and the groups it is associated with
      let dataAttachments = {};

      // for each valid header, process the record
      Object.keys(attachmentGroups).forEach(header =>{
        
        let attachments = record[header];
        
        //if the header is not empty
        if (attachments) {
          // Split the attachments value by comma and process each one
          attachments.split(',').forEach(attachment => {

            let attachmentName = attachment.trim();
            if (attachmentName) {
              // Initialize the group array if it doesn't exist
              if (!dataAttachments[attachmentName]) {
                dataAttachments[attachmentName] = [];
              }

              // Add the group name if it's not already included
              if (!dataAttachments[attachmentName].includes(attachmentGroups[header])) {
                dataAttachments[attachmentName].push(attachmentGroups[header]);
              }
            }
          });
        }
      })
      return dataAttachments;
    },
    cancelUpload() {
      this.jsonFiles = [];
      this.otherFiles = [];
      this.uploadedFiles = [];
      this.uploadAssetDialog = false;
    },
    removeFile(fileName) {
      let index = this.uploadedFiles.findIndex(
        (file) => file.name === fileName
      );
      // If file is in uploaded files remove it
      if (index > -1) this.uploadedFiles.splice(index, 1);
    },
    assetCheck(extension){
      if(this.modelExtensions.includes( extension)) return false;
      if(this.drawingExtensions.includes( extension)) return false;
      if(this.mapExtensions.includes( extension)) return false;
      if(this.dataExtensions.includes( extension)) return false;
      // if(this.imageExtensions.includes( extension)) return false;
      return true;
    },
    updateExtension(f, extensionType) {
      let index = this.uploadedFiles.findIndex((file) => file.name === f.name);
      this.uploadedFiles[index].is2D = "false";
      this.uploadedFiles[index].is3D = "false";
      this.uploadedFiles[index].isGIS = "false";
      this.uploadedFiles[index].isData = "false";
      this.uploadedFiles[index].isAsset = "false";
      this.uploadedFiles[index].isDataImg = "false";
      this.uploadedFiles[index].isFile = "false";
      this.uploadedFiles[index][extensionType] = "true";

      this.uploadedFiles.splice(index, 1, this.uploadedFiles[index]);
      this.radioKey++ //force rerender radio groups
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    /** 
     * Uploads all files to S3 and updates the database with the new file information
     * Triggered by Upload Button. 
     */
    async startUpload(){
      //trigger UI to show upload progress
      this.uploadAssetLoading = true;
      this.cancelUploadDisabled = true;

      //upload files
      await this.uploadOtherFiles(this.otherFiles);
      await this.uploadDatasetFiles(this.jsonFiles);

      //reload assets
      await this.reloadAssets();

      //finish loading ui
      this.uploadAssetLoading = false;
    },
    async uploadOtherFiles(files){
      if (files.length === 0) return
      
      let fileTypeMapping = {
        'is2D':{
          type: "drawings",
          uploadUrl: "/api/drawings/upload-drawing"
        },
        'is3D':{
          type: "models",
          uploadUrl: "/api/models/upload-model"
        },
        'isGIS':{
          type: "maps",
          uploadUrl: "/api/maps/upload-map"
        },
        'isDataImg':{
          type: "images",
          uploadUrl: "/api/images/upload-image"
        },
        'isAsset':{
          type: "files",
          uploadUrl: "/api/files/upload-file"
        }
      }

      //iterate through files and upload them if they have a valid file name
      return Promise.all((files.map(async (file) => {
        let type
        let uploadUrl
        var okayToUpload = true

        for (const [key, value] of Object.entries(fileTypeMapping)) {
          if (file[key] === "true") {
            file.isValidFile = true;
            type = value.type;
            uploadUrl = value.uploadUrl;
          }
        }

        if (type === undefined || uploadUrl ===undefined){
          okayToUpload = false
          console.log('ERROR: file type not recognized', file.name, file.uploadType)
          return false
        } 

        if (okayToUpload){
          let savedFile = await this.uploadToS3(file, type, uploadUrl);
          return savedFile
          //return await this.uploadToS3(file, type, uploadUrl)
        }
      })))
    },
    async uploadDatasetFiles(files){
      if (files.length === 0) return;
      return Promise.all((files.map(async (file) => {
        if (file.isData === "true") {
          let type = "datasets";
          let uploadUrl = "/api/datasets/upload-dataset";
          
          return new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = async (evt) => {
              let text = evt.target.result;
              let isValid = this.isJsonString(text);
              if (isValid) {
                file.isValidFile = true;
                let savedFile = await this.uploadDatasetToS3(file, type, uploadUrl);
                resolve(savedFile)
              }
              else {
                console.log('ERROR: file type not recognized')
              }
            };
          })
          
        }
      })))
    },
    async uploadToS3(file, type, uploadUrl) {
      return new Promise(async (resolve, reject) => {
        if (file.isValidFile) {
          let path = `notebooks/${this.getNotebook._id}/${type}/`;
          let that = this;


          //asset is a file (not model, drawing, or dataset)
          if (file.isAsset === "true") {
            //console.log(file)
            //Create temp file in database
            let urlPrefix = `/api/notebook/${this.getNotebook._id}/file`;
            let data = {
              notebook: this.getNotebook._id,
              folder: this.getNotebook.rootFolder,
              lastModified: file.lastModified,
              lastModifiedDate: file.lastModifiedDate,
              name: file.name,
              size: file.size,
              groups: file.groups || [],
              type: file.type || file.name.split(".")[1],
            };
            let tempPostRoute = `${urlPrefix}/create-temp/?urlType=${that.$route.name}`;
            let newFile = await that.$auth.$api.post(tempPostRoute, data);
            if (newFile.status !== 201) throw new Error("Error creating temp file");
            newFile = newFile.data.data;

            //upload file to S3
            let path = `notebooks/${this.getNotebook._id}/files/`;
            let v = JSON.parse(JSON.stringify(that.showFileUploadProgres));
            v[`${file.name}`] = true;
            that.showFileUploadProgres = v;

            utils.uploadToS3(
              [file],
              path,
              newFile._id,
              this.getNotebook._id,
              async function (progresses, uploadedKey) {
                file["uploadPercentage"] = 50;
                if (uploadedKey) {
                  newFile.s3Key = uploadedKey;
                  newFile.allowAnonymousAccess = true;
                  let savePostRoute = `${urlPrefix}/save/?urlType=${that.$route.name}`;
                  let savedFile = await that.$auth.$api.post(
                    savePostRoute,
                    newFile
                  );
                  file["uploadPercentage"] = 100;
                  that.groupUpdateKey += 1;
                  resolve(savedFile)
                }
              }
            );
          } 
          else { //asset is a model, drawing, map, or dataset

                  let allPageIds = that.getPagesStructuredData.map((page) => page._id);
                  let tempPostData = {
                    name: file.name,
                    pages: [...allPageIds],
                    notebook: that.getNotebook._id,
                  }; 
                  let uploadAssetExtention = file.name.split(".").pop();

                  let uploadAssetType;
                  if (uploadAssetExtention === "3dm") {
                    uploadAssetType = "models";
                  } else if (uploadAssetExtention === "svg") {
                    uploadAssetType = "drawings";
                  } else if (uploadAssetExtention === "geojson") {
                    uploadAssetType = "maps";
                  } else if (type ==="images"){
                    uploadAssetType = "images";
                    //extend post data to fill out Images schema
                    let nickname = file.name; 
                    let extention = uploadAssetExtention; 
                    let size = file.size; 
                    let groups = file.groups || []; 
                    let imageSpecificProps = {
                      nickname,
                      extention,
                      size,
                      groups,
                    };

                    tempPostData = { ...tempPostData, ...imageSpecificProps };
                  } else {
                    uploadAssetType = "datasets";
                  }
                  
                  let tempPostRoute = `/api/${uploadAssetType}/new`;

                  let tempModelResponse = await that.$auth.$api.post(
                    tempPostRoute,
                    tempPostData
                  );

                  if (tempModelResponse.status !== 201) throw new Error("Error creating temp model record");
                  let modelId = tempModelResponse.data.data._id; 
                  // Use this ID in the S3 key

            //upload file to S3
            utils.uploadModelsToS3(
              uploadUrl,
              [file],
              path,
              modelId,
              this.getNotebook._id,
              async function (progresses, uploadedKey, fileName) {
                if (uploadedKey) {
                  let v = JSON.parse(JSON.stringify(that.showFileUploadProgres));
                  v[`${file.name}`] = true;
                  that.showFileUploadProgres = v;
                  file["uploadPercentage"] = 50;
                  let allPageIds = that.getPagesStructuredData.map((page) => page._id);
                  let postData = {
                    name: fileName,
                    s3_pointers: uploadedKey,
                    pages: [...allPageIds],
                    notebook: that.getNotebook._id,
                  };

                  //todo map based on type?
                  //this actulay seems totaly un needed - we already checked extensions. 
                  let uploadAssetExtention = fileName.split(".").pop();
                  let uploadAssetType;
                  if (uploadAssetExtention === "3dm") {
                    uploadAssetType = "model";
                  } else if (uploadAssetExtention === "svg") {
                    uploadAssetType = "drawing";
                  } else if (uploadAssetExtention === "geojson") {
                    uploadAssetType = "map";
                  } else if (type ==="images"){
                    uploadAssetType = "image";
                    //extend post data to fill out Images schema
                    let nickname = file.name; 
                    let extention = uploadAssetExtention; 
                    let size = file.size; 
                    let imageSpecificProps = {
                      nickname,
                      extention,
                      size,
                    };

                    postData = { ...postData, ...imageSpecificProps };
                  } else {
                    uploadAssetType = "dataset";
                  }

                  let savedFile;
                  savedFile = await that.$auth.$api.post(
                    `/api/${uploadAssetType}/${modelId}/edit-info`,
                    postData
                  );

                  if (savedFile.status == 200){
                    file["uploadPercentage"] = 100;
                    that.groupUpdateKey += 1;
                    resolve(true)
                  } 
                  else reject('could not upload file', savedFile.status, savedFile)
                }
              }
            );
          }
        }
      })
    },
    async uploadDatasetToS3(file, type, uploadUrl){
      return new Promise(async (resolve, reject) => {
        if (file.isValidFile) {
          let path = `notebooks/${this.getNotebook._id}/${type}/`;
          let that = this;
          let modelId = "eDataset_"
            //upload file to S3
            utils.uploadModelsToS3(
              uploadUrl,
              [file],
              path,
              modelId,
              this.getNotebook._id,
              async function (progresses, uploadedKey, fileName) {
                if (uploadedKey) {
                  let v = JSON.parse(JSON.stringify(that.showFileUploadProgres));
                  v[`${file.name}`] = true;
                  that.showFileUploadProgres = v;
                  file["uploadPercentage"] = 50;
                  let allPageIds = that.getPagesStructuredData.map((page) => page._id);
                  let postData = {
                    name: fileName,
                    s3_pointers: uploadedKey,
                    pages: [...allPageIds],
                    notebook: that.getNotebook._id,
                  };
                  //todo map based on type?
                  //this actulay seems totaly un needed - we already checked extensions. 
                  let uploadAssetExtention = fileName.split(".").pop();
                  let uploadAssetType = "datasets";
                  
                  let savedFile;
                  savedFile = await that.$auth.$api.post(
                    `/api/${uploadAssetType}/new`,
                    postData
                  );

                  if (savedFile.status == 201){
                    file["uploadPercentage"] = 100;
                    that.groupUpdateKey += 1;
                    resolve(true)
                  } 
                  else reject('could not upload file', savedFile.status, savedFile)
                }
              }
            );
        }
      })
    },
    async reloadAssets() {
      let assets = await this.setAllAssetsInNotebook();
      if (this.getSelectedPageDataset == null && this.getSelectedPageDatasets.length > 0) {
        let set = await this.setSelectedPageDataset(this.getSelectedPageDatasets[0]);
      }
      this.cancelUploadDisabled = false;
      this.allAssetsUploadDone = true;
      this.jsonFiles = [];
      this.otherFiles = [];
      
      return new Promise((resolve) => {
        resolve(assets)
      })
    },
    async clearAssets(){
      this.uploadAssetDialog = false;
      this.allAssetsUploadDone = false;
      this.jsonFiles = [];
      this.otherFiles = [];
      this.uploadedFiles = [];
    },
  },
};
</script>
<style scoped>
.v-input--selection-controls {
  margin-top: 5px !important;
  padding-top: 5px !important;
}

.v-input--radio-group__input {
  background-color: rgb(var(--v-theme-darkSlate)) !important;
}

</style>
